import React from 'react'

const WritingSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.96 85.99">
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M83.62,24.24,67.72,8.34A8,8,0,0,0,62.07,6h0a8,8,0,0,0-5.65,2.34l-9.08,9.08a7.83,7.83,0,0,0-1.06,1.33l-1.4.47-21.07,7a8.69,8.69,0,0,0-5.56,5.55q-1.9,5.76-3.83,11.51L11,53.54Q8.74,60.32,6.46,67.12a8.54,8.54,0,0,0,2.36,9.12c.18.16.35.34.52.52l1.56,1.56a8.11,8.11,0,0,0,1.56,1.2A7.85,7.85,0,0,0,13.64,81l.5.5c.63.64,1.25,1.26,1.86,1.89A8.34,8.34,0,0,0,22,86a8.54,8.54,0,0,0,2.71-.44L60.23,73.72a8.65,8.65,0,0,0,5.51-5.53q2-6,4-12.08l3.49-10.46.31-.19a7.8,7.8,0,0,0,1.1-1l9-9a8,8,0,0,0,0-11.31Z"/><path d="M77.62,18.24,61.72,2.34A8,8,0,0,0,56.06,0h0a8,8,0,0,0-5.65,2.34l-9.08,9.08a7.83,7.83,0,0,0-1.06,1.33l-1.4.47-21.07,7a8.69,8.69,0,0,0-5.56,5.55q-1.9,5.76-3.83,11.51L5,47.54Q2.74,54.32.46,61.12a8.54,8.54,0,0,0,2.36,9.12c.18.16.35.34.52.52L4.9,72.32a8.11,8.11,0,0,0,1.56,1.2A7.85,7.85,0,0,0,7.64,75l.5.5c.63.64,1.25,1.26,1.86,1.89A8.34,8.34,0,0,0,16,80a8.54,8.54,0,0,0,2.71-.44L54.23,67.72a8.61,8.61,0,0,0,5.5-5.53q2-6,4-12.08l3.48-10.46c.11-.07.22-.12.32-.19a7.8,7.8,0,0,0,1.1-1l9-8.95a8,8,0,0,0,0-11.31Z"/><path fill="white" d="M13.32,69.4l.39-.35q8.68-8.69,17.36-17.38a.46.46,0,0,1,.62-.13,6,6,0,0,0,8.16-4.35,5.88,5.88,0,0,0-1.58-5.42A5.71,5.71,0,0,0,34.63,40,5.93,5.93,0,0,0,28,45.4a5.41,5.41,0,0,0,.46,3,.3.3,0,0,1-.07.41L10.62,66.58l-.08.06L9.46,65.57c-.41-.42-.82-.85-1.25-1.25a.54.54,0,0,1-.16-.67q3.3-9.85,6.58-19.72,2.61-7.82,5.21-15.62a.65.65,0,0,1,.48-.48l23.34-7.78.43-.16,16,16c-1.28,3.85-2.6,7.77-3.9,11.7s-2.69,8.06-4,12.1a.65.65,0,0,1-.46.46L16.21,72a.39.39,0,0,1-.46-.11c-.79-.81-1.6-1.62-2.43-2.46Z"/><path fill="white" d="M62.89,33,47,17.07,56.07,8,72,23.9,62.89,33Z"/>
            </g>
        </g>
    </svg>

)

export default WritingSVG
