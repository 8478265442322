/* eslint-disable react/prop-types */
import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { THEME } from '../../../../assets/GlobalTheme'

import Footer from './components/Footer/Footer'
import PageViewTracker from '../../../router/PageViewTracker'
import { LoginRegisterContext, useLoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { UserContext, useUserContext } from 'shared/contexts/UserContext'
import { AlertCTAContext, useAlertCTAContext } from 'shared/contexts/AlertCTAContext'

// IMPLEMENTATION OF TRANSLATION VIA REACT-INTL
import URLHandler from 'assets/URLHandler.js'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import nl from 'react-intl/locale-data/nl'
import translations from 'assets/i18n/translations.json'
import ErrorView from '../../../pages/ErrorView/ErrorView'
import LoginRegisterModal from '../../modals/LoginRegisterModal/LoginRegisterModal'
import MenuNavigation from './components/MenuNavigation/MenuNavigation'

// Initialize all locales for react-intl.
addLocaleData([...en, ...fr, ...nl])

Bugsnag.start({
    apiKey: '81dffaf6b0bb397966fc79d15481a230',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production']
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const PageWrapper = ({ children, ...props }) => {
    const locale = URLHandler.GetLocaleFromUrl(props.user && props.user.language_preference)
    const messages = translations[locale]

    const loginRegisterContextValue = useLoginRegisterContext()
    const userContextValue = useUserContext(props.user)
    const alertContextValue = useAlertCTAContext()

    if (props.user) {
        Bugsnag.setUser(props.user.id, props.user.email, props.user.last_name)
    }

    return (
        <Router basename={`/${locale}/`}>
            <PageViewTracker user={props.user} />
            <MuiThemeProvider theme={THEME}>
                <CssBaseline />
                <IntlProvider locale={locale} key={locale} messages={messages} textComponent={React.Fragment} >
                    <ErrorBoundary FallbackComponent={ErrorView} {...props}>
                        <UserContext.Provider value={userContextValue}>
                            <AlertCTAContext.Provider value={alertContextValue}>
                                {!props.disableNavbar && <>
                                    <MenuNavigation
                                        user={props.user}
                                        page={props.page}
                                        altLangUrls={props.altLangUrls}
                                        selectedArticleTagObject={props.selectedArticleTagObject}
                                        paramsForUrlTranslation={props.paramsForUrlTranslation}
                                        darkMode={props.darkMode}
                                    />
                                </>
                                }
                                <LoginRegisterContext.Provider value={loginRegisterContextValue}>
                                    {!props.user && <LoginRegisterModal
                                        showModal={loginRegisterContextValue.showLoginModal}
                                        setShowModal={loginRegisterContextValue.setShowLoginModal}
                                        origin={loginRegisterContextValue.loginRegisterConfig.origin}
                                        onClick={loginRegisterContextValue.loginRegisterConfig.onClick}
                                        afterAuthAction={loginRegisterContextValue.loginRegisterConfig.afterAuthAction}
                                        loginTitle={loginRegisterContextValue.loginRegisterConfig.loginTitle}
                                        loginSubTitle={loginRegisterContextValue.loginRegisterConfig.loginSubTitle}
                                    />}
                                    <div className='pageWrapper-content-container'>
                                        {children}
                                    </div>
                                </LoginRegisterContext.Provider>
                                {!props.disableFooter &&
                                <Footer
                                    user={props.user}
                                    altLangUrls={props.altLangUrls}
                                    paramsForUrlTranslation={props.paramsForUrlTranslation}
                                    disableFooterLists={props.disableFooterLists}
                                />
                                }
                            </AlertCTAContext.Provider>
                        </UserContext.Provider>
                    </ErrorBoundary>
                </IntlProvider>
            </MuiThemeProvider>
        </Router>
    )
}

PageWrapper.propTypes = {
    user: PropTypes.object,
    disableNavbar: PropTypes.bool,
    disableFooter: PropTypes.bool
}

export default PageWrapper
