import React from 'react'

const WinkSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.95 86">
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M46,6a40,40,0,0,0,0,80H46A40,40,0,0,0,46,6Z"/>
                <path d="M40,0a40,40,0,0,0,0,80H40A40,40,0,0,0,40,0Z"/>
                <path fill="white" fillRule='evenodd' d="M40,72A32,32,0,1,0,8,40,31.9,31.9,0,0,0,40,72ZM18.15,42.51H60.23c-.37,7.64-6.8,16.88-17.48,18.65A21.18,21.18,0,0,1,18.15,42.51Zm25.06-7.13h0a6.15,6.15,0,0,1,9.67,0l3.79-2.68,0,0a10.63,10.63,0,0,0-17.25,0l3.65,2.6ZM28,36a4,4,0,1,0-4-4A4,4,0,0,0,28,36Z"/>
            </g>
        </g>
    </svg>
)

export default WinkSVG
