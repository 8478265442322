export const useStyles = (theme) => ({
    root: {
        display: 'flex'
    },
    titleColumn1: {
        lineHeight: '25px'
    },
    link1: {
        listStyle: 'none',
        paddingLeft: 0,
    },
    footerContainer: {
        zIndex: 9000,
        backgroundColor: theme.palette.bg['purple'],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '80px 80px 0px 80px',
        [theme.breakpoints.down('sm')]: {
            padding: '44px 32px 0px 32px'
        },
    },
    footerTitle: {
        color: 'white !important',
        margin: 'unset !important',
        fontSize: '16px !important',
        lineHeight: 1.3,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    logoContainer: {
        marginRight: 50,
    },
    titleColumn: {
        color: 'white',
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px'
        }
    },
    link: {
        color: 'white',
        marginTop: 10,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    textFollowUs: {
        color: 'white',
        fontFamily: 'GreedStandard-regular',
        fontSize: '16px',
    },
    footerFollowUsContainer: {
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            marginBottom: 69
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '19px'
    },
    allCity: {
        color: 'var(--red) !important',
        fontFamily: 'GintoNormal-Medium',
        textDecoration: 'none !important'
    },
    divider: {
        backgroundColor: 'white',
        color: 'white'
    },
    footerBottomLinks: {
        alignItems: 'center',
    },
    footerLeftBox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    footerRightBox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        },
    },
    dividerLangIcons: {
        backgroundColor: 'white',
        height: 26,
        margin: '0 20px'
    },
    copyrightTypo: {
        fontSize: 16,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    iconCopyright: {
        fontSize: 14,
        marginRight: 5,
    },
    iconsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '236.16px'
    },
    iconButton: {
        padding: 0
    },
    icon: {
        color: 'white',
        fontSize: 20
    },
    logo: {
        height: 62,
        maxWidth: 200,
        objectFit: 'contain',
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'start',
            marginBottom: 40,
        },
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'center',
            height: 64,
            maxWidth: 208,
            marginBottom: 40,
        },
    },
    containerBottom: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '70px',
        marginBottom: '54px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            marginBottom: '33px',
            gap: '23px'
        },
    },
    containerBottomLeft: {
        display: 'flex',
        alignItems: 'center'
    },
    containerBottomRight: {
        display: 'flex',
    },
    containerLangSelect: {
        paddingTop: '4px'
    },
    wrapperFooter: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },

    wrapperItemFirst: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1.30,
    },
    wrapperItemSecond: {
        padding: '0px 10px',
        display: 'flex',
        flex: 1.2,
        flexDirection: 'column',
        gap: '40px',
        [theme.breakpoints.down('xs')]: {
            gap: '106px',
        },
    },
    wrapperSubItemSecond: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    wrapperItemSecondAndThird: {
        display: 'flex',
        flex: 2.4,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    wrapperItemSecondAndThirdMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '45px'
        }
    },
    wrapperRowMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            gap: '15px'
        },
    },
    wrapperRowItemMobile: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    wrapperItemThirdAndFourth: {
        display: 'flex',
        flex: 2,
        [theme.breakpoints.down('xs')]: {
            flex: 1,
            flexDirection: 'column',
            gap: '27px'
        },
    },
    wrapperItemThird: {
        padding: '0px 10px',
        display: 'flex',
        flex: 1.2,
        flexDirection: 'column'
    },
    wrapperItemFourth: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 40
    }
})
