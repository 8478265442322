import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    fieldButton: {
        border: '1px solid var(--background-grey-pale)',
        padding: '8px 14px',
        cursor: 'pointer',
        textTransform: 'none',
        height: '48px',
        borderRadius: '36px',
        width: '100%',
        '&:hover': {
            border: '1px solid black',
            backgroundColor: 'white',
            boxShadow: 'var(--box-shadow)'
        }
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    cityContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    cityName: {
        lineHeight: 1.1
    },
    cityChip: {
        height: '24px',
        borderRadius: '36px',
        backgroundColor: '#E4F8E4',
        fontSize: '10px',
        '& .MuiChip-label': {
            color: 'var(--green)'
        }
    },
    openJobsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    openJobs: {
        color: 'var(--grey-middle)',
        lineHeight: '18.33px',
        textAlign: 'left'
    },
    linkIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg.black
    }
}))
