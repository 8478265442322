const useNavigationTypeToItemType = (type) => {
    switch (type) {
    case 'student-jobs': return 'student_job'
    case 'internships': return 'internship'
    case 'first-jobs': return 'first_job'
    case 'student-rooms': return 'kot'
    }
}

export default useNavigationTypeToItemType
