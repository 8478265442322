import React from 'react'
import { Helmet } from 'react-helmet'
import CollectionHandler from 'assets/CollectionHandler.js'
import Sanitizer from 'assets/Sanitizer.js'
import RouteMap from 'assets/RouteMap.js'
import { injectIntl } from 'react-intl'
import DefaultHelmet from './DefaultHelmet'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

class JobShowHelmet extends React.Component {
    employmentType () {
        if (this.props.job.type === 'internship' || this.props.job.contract_type === 'paid_internship') return 'INTERNSHIP'
        if (this.props.job.contract_type === 'fixed_permanent_contract') return 'FULL_TIME'
        if (this.props.job.contract_type === 'freelance') return 'CONTRACTOR'
        if (this.props.job.contract_type === 'interim' || this.props.job.contract_type === 'fixed_term_contract') return 'TEMPORARY'
        if (this.props.job.regime && this.props.job.regime.includes('part_time')) return 'PART_TIME'

        return 'FULL_TIME'
    }

    getMainJobLanguage () {
        if (this.props.job.fr && this.props.job.nl) return 'en'
        if (this.props.job.fr) return 'fr'
        if (this.props.job.nl) return 'nl'
        return 'en'
    }

    render () {
        const { formatMessage } = this.props.intl
        let activeUntil = new Date()
        if (this.props.job.type === 'first_job') {
            if (this.props.job.deadline !== null) {
                activeUntil = this.props.job.deadline
            } else {
                activeUntil = this.props.job.active_until
            }
        } else if (this.props.job.updated_at) {
            activeUntil = new Date(this.props.job.updated_at)
            activeUntil = new Date(activeUntil.setMonth(activeUntil.getMonth() + 6))
        } else {
            activeUntil = activeUntil.setMonth(activeUntil.getMonth() + 6)
        }
        return <>
            <DefaultHelmet
                title={`${this.props.job.title} | ${formatMessage({ id: `type.${this.props.job.type}.singular` })} | Student.be`}
                description={this.props.job.description?.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 155)}
                imageCloudinaryKey={this.props.job.company_logo_cloudinary_key}
                noindex={this.props.noindex}
            />
            <Helmet>
                <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                <meta property='og:updated_time' content={this.props.job.updated_at} /> {/* When the job was last changed. */}
                <link rel="canonical" href={RouteMap.Show(this.props.job.type, this.props.job.url, this.getMainJobLanguage())} />
                { ((this.props.job.active && this.props.job.approved && this.props.job.type !== 'first_job') ||
                    (this.props.job.active && this.props.job.approved && this.props.job.type === 'first_job' && Date.parse(this.props.job.active_until) > Date.now())) &&
                    <script type="application/ld+json">
                        {`{
                            "@context" : "https://schema.org/",
                            "@type" : "JobPosting",
                            "directApply" : "True",
                            "title" : "${Sanitizer.SanitizeForApplicationJson(this.props.job.title || '')}",
                            "description" : "${Sanitizer.SanitizeForApplicationJson(this.props.job.description || '')}",
                            "image" : "${this.props.job.job_show_cloudinary_key && CloudinaryURLHelper.getImageUrl('JOB_SHOW_HELMET', this.props.job.job_show_cloudinary_key)}",
                            "identifier": {
                                "@type": "PropertyValue",
                                "name": "Student.be",
                                "value": "${this.props.job.type}_${this.props.job.id}"
                            },
                            "datePosted" : "${this.props.job.updated_at}",
                            "validThrough" : "${activeUntil}",
                            "employerOverview" : "${Sanitizer.SanitizeForApplicationJson(this.props.job.company_description || '')}",
                            "qualifications" : "${Sanitizer.SanitizeForApplicationJson(this.props.job.searched_profile || '')}",
                            "jobBenefits" : "${Sanitizer.SanitizeForApplicationJson(this.props.job.we_offer || '')}",
                            "employmentType" : "${this.employmentType()}",
                            "industry" : "${this.props.job.sector && CollectionHandler.Translate('sectorCollection', this.props.job.sector)}",
                            "totalJobOpenings   " : "${this.props.job.number_of_people_sought || 1}",
                            "hiringOrganization" : {
                                "@type" : "Organization",
                                "name" : "${this.props.job.company_name || this.props.job.company.name}",
                                "sameAs" : "${RouteMap.Show('company', this.props.job.company_url)}",
                                "logo" : "${this.props.job.company_logo_cloudinary_key && CloudinaryURLHelper.getImageUrl('HELMET_LOGO', this.props.job.company_logo_cloudinary_key)}"
                            },
                            "jobLocation": {
                                "@type": "Place",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "${this.props.job.address.street || ''}",
                                    "addressLocality": "${this.props.job.address.city}",
                                    "addressCountry": "BELGIUM"
                                }
                            }
                        }`}
                    </script>
                }
            </Helmet>
        </>
    }
}

export default injectIntl(JobShowHelmet)
