import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dropDownArticlesContainer: {
        maxWidth: '520px',
        minWidth: '480px',
        padding: '20px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between'
    },
    dropDownArticlesTitle: {
        color: 'var(--foreground-black)',
        fontSize: 24,
        textTransform: 'uppercase'
    },
    articlesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        height: '100%'
    },
    dropdownSeeMoreLink: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    seeAll: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        borderRadius: '8px',
        padding: '10px 14px',
        fontSize: 16
    }
}))
