import React, { useRef, useState } from 'react'
import { useStyles } from '../Searchbar_v2_style'
import { Box, Hidden, Typography, Container } from '@material-ui/core'
import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterArrows from '../shared/QuickFilterArrows/QuickFilterArrows'
import QuickFilterTag from '../shared/QuickFilterTag/QuickFilterTag'
import { FormattedMessage } from 'react-intl'

const InternshipQuickFilters = (props) => {
    const classes = useStyles()
    const { filters, setFilters } = props
    const quickFilterInternshipCollection = CollectionHandler.Get('quickFilterInternshipCollection')
    const quickFiltersRef = useRef(null)
    const [isHoverText, setIsHoverText] = useState(null)

    return (
        <Container className={classes.quickFilterContainer} maxWidth='lg' disableGutters>
            <Box className={classes.quickFiltersInner}>
                <Box className={classes.quickFilters} ref={quickFiltersRef}>
                    {quickFilterInternshipCollection.map(quickFilterOption => {
                        return (
                            <QuickFilterTag
                                key={quickFilterOption.value}
                                filters={filters}
                                setFilters={setFilters}
                                quickFilterOption={quickFilterOption}
                                quickFilterSelection={filters.quickFilter}
                                page='internships'
                            />
                        )
                    })}
                </Box>
                <Hidden smDown>
                    <QuickFilterArrows quickFiltersRef={quickFiltersRef} />
                </Hidden>
            </Box>
        </Container>
    )
}

export default InternshipQuickFilters
