import React, { useEffect, useState } from 'react'

import { useStyles } from './NavigationDropDownArticles_style'
import { Box, Typography } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import ArticleAPI from 'api/ArticleAPI'
import NavigationDropDownArticleCard from '../NavigationDropDownArticleCard/NavigationDropDownArticleCard'
import MultiFontText from 'shared/components/MultiFontText/MultiFontText'
import useNavigationTypeToItemType from 'hooks/useNavigationTypeToItemType'
import NavigationLinkArticles from '../NavigationLinkArticles/NavigationLinkArticles'

const NavigationDropDownArticles = (props) => {
    const classes = useStyles()
    const { type } = props
    const itemType = useNavigationTypeToItemType(type)

    const [articles, setArticles] = useState(false)

    useEffect(() => {
        ArticleAPI.GetRecentArticles({
            category: transformCategoryFormat(type),
            count: 2
        }).then((response) => {
            setArticles(response.data)
        })
    }, [])

    const transformCategoryFormat = (category) => {
        if (category === 'student-rooms') {
            category = 'kots'
        }
        return category.slice(0, -1).replace('-', '_')
    }

    const renderArticleCards = () => {
        return articles.map((article, index) =>
            <NavigationDropDownArticleCard
                article={article}
                key={`navigation-article-card-${index}`}
            />
        )
    }

    return (
        <Box className={classes.dropDownArticlesContainer}>
            <MultiFontText>
                <FormattedMessage id={`type.${itemType}.singular`}>
                    {i18nItem =>
                        <FormattedMessage id='page_footer_navbar_data.articles_title.item_type' values={{ item_type: i18nItem }}>
                            {text => <Typography className={classes.dropDownArticlesTitle} variant='h2' dangerouslySetInnerHTML={{ __html: text }} />}
                        </FormattedMessage>
                    }
                </FormattedMessage>
            </MultiFontText>
            <Box className={classes.articlesContainer}>
                {articles && renderArticleCards()}
            </Box>
            <NavigationLinkArticles itemType={itemType} />
        </Box>
    )
}

export default NavigationDropDownArticles
