import React from 'react'
import { useStyles } from './ProviderLoginButton_style'
import { FormattedMessage } from 'react-intl'
import { Box } from '@material-ui/core'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import Button from 'shared/components/Button/Button'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import URLHandler from 'assets/URLHandler'

const ProviderLoginButton = (props) => {
    const { provider } = props
    const classes = useStyles()

    const providerTargets = {
        facebook: 'facebook',
        apple: 'apple',
        google: 'google_oauth2'
    }

    const PROVIDER_TARGET = providerTargets[provider]
    const PROVIDER_LOGIN_URL = `/users/auth/${PROVIDER_TARGET}?locale=${URLHandler.GetLocaleFromUrl()}`

    const onClick = () => {
        AnalyticsHelper.sendGTMEvent(`${provider}-login-click`)
        window.location.href = PROVIDER_LOGIN_URL
    }

    return (
        <Button
            variant='secondary'
            className={classes.button}
            onClick={onClick}
            startIcon={<Box className={classes.iconBox}>
                <SVGFactory name={provider} skipSvgWrapper height='20px' />
            </Box>}
        >
            <FormattedMessage id={`landing_login_page.login.with_${provider}`} />
        </Button>
    )
}

export default ProviderLoginButton


