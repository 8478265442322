import React from 'react'

const AppleSVG = (props) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" {...props}>
      <g>
        <path d="M838.63,585.71c-.34-62.99,23.53-113,71.87-152.09,16.77-13.56,17.95-38.94,2.24-53.71-36.67-34.49-85.17-53.94-145.01-58.57-72.53-5.72-151.8,42.29-180.81,42.29-30.65,0-100.93-40.25-156.09-40.25-114,1.84-235.16,90.92-235.16,272.14,0,53.53,9.81,108.83,29.42,165.9,26.15,74.98,120.54,258.86,219.02,255.8,51.49-1.23,87.85-36.57,154.87-36.57s98.68,36.57,156.09,36.57c87.46-1.26,164.15-131.08,198.22-213.39,6.75-16.31.55-35.07-14.5-44.29-106.34-65.14-100.15-170.21-100.15-173.82Z"/>
        <path d="M694.03,261.63c30.26-35.91,42.6-70.07,47.29-97.28,4.72-27.32-21.81-49.5-47.81-39.89-34.27,12.66-67.29,34.88-89.18,60.35-25.99,29.41-44.21,63.89-50.49,102.19-4.19,25.55,19.61,47.06,44.59,40.28,35.19-9.56,67.51-32.83,95.6-65.65Z"/>
      </g>
      <g>
        <path d="M744.08,525.23c-.39-72.26,32.29-126.8,98.44-166.96-37.01-52.96-92.93-82.1-166.76-87.81-69.89-5.51-146.29,40.76-174.24,40.76-29.53,0-97.26-38.79-150.42-38.79-109.86,1.77-226.62,87.61-226.62,262.25,0,51.58,9.45,104.87,28.35,159.87,25.2,72.26,116.16,249.46,211.06,246.5,49.62-1.18,84.66-35.24,149.24-35.24s95.1,35.24,150.42,35.24c75.62-1.09,142.88-101.91,179.62-179.91,13.16-27.93,5.09-61.54-19.69-79.95-84.68-62.89-79.4-152.69-79.4-155.96Z" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="49"/>
        <path d="M632.64,201.94c40.42-47.97,47.66-92.7,48.1-120.82.19-12.21-11.12-21.4-23.06-18.84-41.5,8.89-84.96,34.79-111.46,65.63-28.78,32.56-47.67,71.58-50.59,115.21-1.02,15.24,12.06,27.61,27.2,25.63,40.81-5.34,78.1-29.76,109.82-66.81Z" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="49"/>
      </g>
    </svg>
)

export default AppleSVG
