import React from 'react'
import { useStyles } from './CTABlock_style'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'shared/components/Button/Button'
import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CTABlock = (props) => {
    const { selectedLocationTag, type, page } = props
    const classes = useStyles()

    return (
        <Box>
            <Container maxWidth="lg" disableGutters className={classes.ctaContainer}>
                <Box className={classes.maxWidth}>
                    <Typography variant="h2">
                        <FormattedMessage id="cta_block.dont_find" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} />, location_name: selectedLocationTag.name }} />
                    </Typography>
                    <Box my={5}>
                        <Typography variant="body1">
                            <FormattedMessage id="cta_block.text1" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} />, location_name: selectedLocationTag.name }} />
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage id="cta_block.text2" values={{ location_name: selectedLocationTag.name }}/>
                        </Typography>
                    </Box>
                    <RouteMapLink page={page}>
                        <Button variant='cta-primary' className={classes.button}>
                            <FormattedMessage id="cta_block.see_all_jobs" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} /> }} />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
        </Box>
    )
}

export default CTABlock
