import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        backgroundColor: 'var(--background-yellow)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '8px 0'
    },
    scrollingContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        animation: '$scroll 40s linear infinite'
    },
    joinTheClubContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    joinTheClubText: {
        fontSize: 24,
    },
    '@keyframes scroll': {
        '0%': {
            transform: 'translateX(0)', // Start just outside the right
        },
        '100%': {
            transform: 'translateX(-100%)', // Move fully outside to the left
        }
    }
}))
