import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './NavigationDropDownArticleCard_style'
import { Box, Button, Typography } from '@material-ui/core'

import DateHandler from 'assets/DateHandler'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const NavigationDropDownArticleCard = (props) => {
    const classes = useStyles()
    const { article } = props

    return (
        <RouteMapLink className={classes.articleCardContainer} redirectionUrl={article.full_url}>
            <Box className={classes.articleCard}>
                <Box className={classes.articlePictureContainer}>
                    <Image
                        className={classes.articlePicture}
                        cloudName="studentbe"
                        publicId={article.cover_image.cloudinary_key}
                        alt={article.cover_image.cloudinary_key}
                    >
                        <Transformation
                            flags="progressive"
                            fetchFormat="auto"
                            width='200'
                            height='128'
                            quality="auto:best"
                            crop="fill"
                        />
                    </Image>
                </Box>
                <Box className={classes.articleContent}>
                    <Box>
                        <Typography variant='body1' component='p' className={classes.articleTypograhy}>
                            {article.title}
                        </Typography>
                    </Box>
                    <Box className={classes.articleReadTimeContainer}>
                        <Box>
                            <IconFactory
                                icon='clock'
                                fontSize='12px'
                                color='var(--foreground-grey-medium)'
                                style={{
                                    fontFamily: 'GreedStandard-Reguler, sans-serif !important',
                                    margin: '0 4px'
                                }}
                            />
                            <Typography variant='body2' component='span' className={classes.articleReadTime}>
                                {`${article.read_time} min`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default NavigationDropDownArticleCard
