import React from 'react'

const StarWinkleSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.18 85.59">
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M98.09,44.84l-.7-.17c-7.3-1.79-7.84-5.31-8.07-6.82l-1.46-9.48A8,8,0,0,0,80,21.59H78.41a8,8,0,0,0-7.9,6.78l-1.46,9.48c-.24,1.51-.78,5-8.08,6.82l-.69.17a8,8,0,0,0-6.1,7.77v2a8,8,0,0,0,6.1,7.77l.69.17c7.3,1.79,7.84,5.31,8.08,6.82l1.46,9.48a8,8,0,0,0,7.9,6.78H80a8,8,0,0,0,7.91-6.78l1.46-9.48c.23-1.51.77-5,8.07-6.82l.7-.17a8,8,0,0,0,6.09-7.77v-2A8,8,0,0,0,98.09,44.84Z"/>
                <path d="M49.12,21.76a13.15,13.15,0,0,1-9.67-9.67A8,8,0,0,0,31.69,6H29.53a8,8,0,0,0-7.77,6.09,13.14,13.14,0,0,1-9.67,9.67A8,8,0,0,0,6,29.53v2.15a8,8,0,0,0,6.09,7.77,13.15,13.15,0,0,1,9.67,9.67,8,8,0,0,0,7.77,6.1h2.16a8,8,0,0,0,7.76-6.1,13.17,13.17,0,0,1,9.67-9.67,8,8,0,0,0,6.1-7.77V29.53A8,8,0,0,0,49.12,21.76Z"/>
                <path fill="white" d="M23.53,45.22a4,4,0,0,1-3.88-3,17.14,17.14,0,0,0-12.6-12.6,4,4,0,0,1-3-3.88V23.53a4,4,0,0,1,3.05-3.88,17.15,17.15,0,0,0,12.6-12.6,4,4,0,0,1,3.88-3h2.16a4,4,0,0,1,3.88,3.05,17.15,17.15,0,0,0,12.6,12.6,4,4,0,0,1,3,3.88v2.16a4,4,0,0,1-3,3.88,17.14,17.14,0,0,0-12.6,12.6,4,4,0,0,1-3.88,3Z"/>
                <path d="M25.69,8A21.17,21.17,0,0,0,41.22,23.53v2.15A21.2,21.2,0,0,0,25.69,41.22H23.53A21.2,21.2,0,0,0,8,25.68V23.53A21.17,21.17,0,0,0,23.53,8h2.16m0-8H23.53a8,8,0,0,0-7.77,6.09,13.14,13.14,0,0,1-9.67,9.67A8,8,0,0,0,0,23.53v2.15a8,8,0,0,0,6.09,7.77,13.15,13.15,0,0,1,9.67,9.67,8,8,0,0,0,7.77,6.1h2.16a8,8,0,0,0,7.76-6.1,13.17,13.17,0,0,1,9.67-9.67,8,8,0,0,0,6.1-7.77V23.53a8,8,0,0,0-6.1-7.77,13.15,13.15,0,0,1-9.67-9.67A8,8,0,0,0,25.69,0Z"/>
                <path fill="white" d="M73.41,76.59a4,4,0,0,1-4-3.39L68,63.72c-.8-5.16-4.42-8.46-11.08-10.09l-.69-.17a4,4,0,0,1-3-3.89v-2a4,4,0,0,1,3-3.89l.7-.17C63.58,41.92,67.21,38.62,68,33.46L69.46,24a4,4,0,0,1,4-3.39H75a4,4,0,0,1,4,3.39l1.46,9.48c.79,5.16,4.41,8.46,11.07,10.1l.69.16a4,4,0,0,1,3.05,3.89v2a4,4,0,0,1-3,3.89l-.7.17c-6.66,1.63-10.28,4.93-11.07,10.09L78.91,73.2a4,4,0,0,1-4,3.39Z"/>
                <path d="M75,24.59l1.46,9.48c1.16,7.5,6.71,11.56,14.08,13.37l.69.17v2l-.69.17c-7.37,1.81-12.92,5.87-14.08,13.37L75,72.59H73.41L72,63.11c-1.15-7.5-6.71-11.56-14.07-13.37l-.7-.17v-2l.7-.17C65.24,45.63,70.8,41.57,72,34.07l1.46-9.48H75m0-8H73.41a8,8,0,0,0-7.9,6.78l-1.46,9.48c-.24,1.51-.78,5-8.08,6.82l-.69.17a8,8,0,0,0-6.1,7.77v2a8,8,0,0,0,6.1,7.77l.69.17c7.3,1.79,7.84,5.31,8.08,6.82l1.46,9.48a8,8,0,0,0,7.9,6.78H75a8,8,0,0,0,7.91-6.78l1.46-9.48c.23-1.51.77-5,8.07-6.82l.7-.17a8,8,0,0,0,6.09-7.77v-2a8,8,0,0,0-6.09-7.77l-.7-.17c-7.3-1.79-7.84-5.31-8.07-6.82l-1.46-9.48A8,8,0,0,0,75,16.59Z"/>
            </g>
        </g>
    </svg>

)

export default StarWinkleSVG
