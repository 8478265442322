/* eslint-disable react/prop-types */
import React, { useEffect, useImperativeHandle } from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Hidden, Link } from '@material-ui/core'

import IconFactory from 'shared/icons/IconFactory/IconFactory'

import { FormattedMessage, injectIntl } from 'react-intl'

// eslint-disable-next-line react/display-name
const SharePopup = React.forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getActionPopup: () => {
            actionPopup()
        }
    }))

    const { formatMessage } = props.intl

    useEffect(
        () => {
            props.getPopupContent({
                popupTitle: renderTitle(),
                popupCancelButton: <FormattedMessage id='share_popup.close_button' />,
                onlyClose: true
            })
        }, [])

    const actionPopup = () => {
        props.popupExit()
    }

    const renderTitle = () => {
        return (
            <Typography variant='h2'>
                {
                    props.infos.title
                        ? <><FormattedMessage id={`share_popup.share_${props.infos.type === 'company' ? 'company_page' : 'offer'}`} /> : {props.infos.title}</>
                        : props.infos.type === 'article'
                            ? <FormattedMessage id='share_popup.share_article' />
                            : <FormattedMessage id='share_popup.share_offer' />
                }
            </Typography>
        )
    }

    const renderShareSubject = () => {
        var subjectType
        switch (props.infos.type) {
        case 'company':
            subjectType = formatMessage({ id: 'share_popup.share_type.company' })
            break
        case 'article':
            subjectType = formatMessage({ id: 'share_popup.share_type.article' })
            break
        case 'first_job':
        case 'internship':
        case 'student_job':
            subjectType = formatMessage({ id: 'share_popup.share_type.job' })
            break
        case 'kot':
            subjectType = formatMessage({ id: 'share_popup.share_type.kot' })
            break
        }
        return subjectType
    }

    const renderBodyText = () => {
        return `${formatMessage({ id: 'share_popup.share_text_part_one' })} ${renderShareSubject()} ${formatMessage({ id: 'share_popup.share_text_part_two' })}`
    }

    const renderBodyUrl = () => {
        return `${props.infos.url}`
    }

    return (
        <Box>
            <Button
                fullWidth
                target='_blank'
                href={`https://www.facebook.com/sharer.php?u=${renderBodyUrl()}`}
                rel='noopener'
                startIcon={<IconFactory icon='facebook' />}
            >
                        Facebook
            </Button>
            <Hidden only={['md', 'lg', 'xl']}>
                <Link
                    target='_blank'
                    href={`fb-messenger://share/?app_id=1313214995677721&link=${renderBodyUrl()}`}
                    rel='noopener'
                >
                    <Button
                        fullWidth
                        startIcon={<IconFactory icon='messenger' />}
                    >
                                Messenger
                    </Button>
                </Link>
                <Link
                    target='_blank'
                    href={`whatsapp://send?text=${renderBodyText()} : ${renderBodyUrl()}`}
                    rel='noopener'
                >
                    <Button
                        fullWidth
                        startIcon={<IconFactory icon='whatsapp' />}
                    >
                            Whatsapp
                    </Button>
                </Link>
            </Hidden>

            <Link
                target='_blank'
                href={`https://twitter.com/intent/tweet?url=${renderBodyUrl()}&text=${renderBodyText()}&hashtags=student.be,jobs`}
                rel='noopener'
            >
                <Button
                    fullWidth
                    startIcon={<IconFactory icon='twitter' />}
                >
                            Twitter
                </Button>
            </Link>
            <Link
                target='_blank'
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${renderBodyUrl()}`}
                rel='noopener'
            >
                <Button
                    fullWidth
                    startIcon={<IconFactory icon='linkedin' />}
                >
                    LinkedIn
                </Button>
            </Link>
            <Link
                target='_blank'
                href={`mailto:?subject=${renderBodyText()}&body=${renderBodyUrl()}`}
                rel='noopener'
            >
                <Button
                    fullWidth
                    startIcon={<IconFactory icon='mail' />}
                >
                            Email
                </Button>
            </Link>

        </Box>

    )
})

export default injectIntl(SharePopup)
