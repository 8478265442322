import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    pageContainer: {
        width: '100%'
    },
    lottieIconOne: {
        position: 'absolute',
        width: 150,
        top: '4%',
        left: '23%',
        zIndex: 200
    },
    lottieIconTwo: {
        position: 'absolute',
        width: 150,
        top: '10%',
        right: '16%',
        zIndex: 200
    },
    filterViewContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    quickFiltersContainer: {
        width: '100%',
        padding: '35px 0px 55px 28px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '8px',
        [theme.breakpoints.up('sm')]: {
            padding: '37px 71px 61px',
            gap: '8px'
        }
    },
    quickFiltersTitle: {
        color: theme.palette.fg['black'],
        fontSize: '13px !important',
        lineHeight: '23px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '12px !important',
            lineHeight: '21px'
        }
    },
    quickFilters: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        overflowX: 'auto',
        flex: 1,
        display: 'flex',
        paddingBottom: '20px',
        alignItems: 'center',
        columnGap: '25px',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            gap: '14px'
        },
        [theme.breakpoints.up('md')]: {
            scrollbarWidth: 'auto',
            '&::-webkit-scrollbar': {
                display: 'block'
            }
        }
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['greyPale'],
        borderRadius: '73px',
        padding: '0px 27px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.bg['blue'],
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
            '& span': {
                color: theme.palette.fg['black']
            },
        },
        [theme.breakpoints.up('sm')]: {
            borderRadius: '67px'
        }
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '15px',
        lineHeight: '26px',
        // textTransform: 'capitalize',
        transition: 'all 0.3s ease',
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px',
            lineHeight: '24px'
        }
    },
    pageContent: {
        width: '100%',
        padding: '0px 22px 43px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '44px',
        [theme.breakpoints.up('sm')]: {
            rowGap: '48px',
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '30px'
        },
        [theme.breakpoints.up(1324)]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    },
    trendArticesSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '38px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    articleMinimalCardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
        [theme.breakpoints.up('md')]: {
            flex: 1
        }
    },
    recentArticlesSection: {
        width: '100%'
    },
    classicArticlesSection: {
        width: '100%'
    },
    classicArticlesSectionTitle: {
        paddingBottom: '26px',
        color: theme.palette.fg['black'],
        fontSize: '24px',
        lineHeight: '28px',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '20px'
        }
    },
    promotionCardContainer: {
        width: 197,
        scrollSnapAlign: 'center'
    }
}))
