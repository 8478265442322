import React, { useState } from 'react'
import { Badge, Chip, TextField } from '@material-ui/core'
import { useStyles } from './JobListingGenerator_style'
import axios from 'axios'
import FunnelOverviewWrapper from '../shared/wrappers/FunnelOverviewWrapper/FunnelOverviewWrapper'
import FunnelContentWrapper from '../shared/wrappers/FunnelContentWrapper/FunnelContentWrapper'
import PageWrapper from '../../../../shared/wrappers/PageWrapper/PageWrapper'
import JobCreationFunnelWrapper from '../../../../shared/wrappers/JobCreationFunnelWrapper/JobCreationFunnelWrapper'
import RouteMap from 'assets/RouteMap.js'
import FunnelSubmitBar from '../shared/FunnelSubmitBar/FunnelSubmitBar'
import Bugsnag from '@bugsnag/js'
import FunnelTableOfContents from '../shared/FunnelTableOfContents/FunnelTableOfContents'
import { FormattedMessage } from 'react-intl'
import URLHandler from '../../../../../assets/URLHandler'
import Box from '@material-ui/core/Box'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const JobListingGenerator = (props) => {
    const classes = useStyles()
    const { user, company } = props
    const [prompt, setPrompt] = useState('')
    const maxWords = 30
    const maxChars = 300
    const [steps] = useState([{ value: 'job_offer_generator' }])
    const currentStep = steps[0].value
    const [wordsLeft, setWordsLeft] = useState(maxWords)
    const [isLoading, setIsLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [jobType] = useState(localStorage.getItem('jobType'))
    const locale = URLHandler.GetLocaleFromUrl()

    localStorage.removeItem('modalClosed')

    const handleChange = (event) => {
        const value = event.target.value
        const wordCount = value.trim() === '' ? 0 : value.trim().split(/\s+/).length
        const charsCount = value.length

        if (wordCount <= maxWords && charsCount <= maxChars) {
            setPrompt(value)
            setWordsLeft(maxWords - wordCount)
        }
    }

    const handleSubmit = () => {
        if (prompt.trim().length > 0) {
            setIsLoading(true)
            generateJobWithAi()
        }
    }
    const generateJobWithAi = () => {
        const data = { prompt: prompt, company_name: company.name, locale: locale }
        axios({
            method: 'post',
            url: '/api/v1/users/generate',
            data: data,
            timeout: 25000
        }).then(response => {
            const jobData = response.data
            localStorage.setItem('jobGeneratorResponse', JSON.stringify(jobData))
            setIsLoading(false)
            window.location.href = RouteMap.Page(`users/${jobType}s/new`)
        }).catch(error => {
            setIsLoading(false)
            setSnackbarOpen(true)
            Bugsnag.notify(error)
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarOpen(false)
    }

    return (
        <PageWrapper user={user} disableFooter>
            <JobCreationFunnelWrapper>
                <FunnelOverviewWrapper>
                    <FunnelTableOfContents steps={steps} currentStep={currentStep}/>
                    <FormattedMessage id="job_creation_funnel.job_offer_generator.bug_reports"></FormattedMessage>
                    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            <FormattedMessage id="job_creation_funnel.job_offer_generator.error"></FormattedMessage>
                        </Alert>
                    </Snackbar>
                </FunnelOverviewWrapper>
                <FunnelContentWrapper>
                    <Box className={classes.parent}>
                        <Box className={classes.indication_title}><FormattedMessage
                            id={'job_creation_funnel.job_offer_generator.indications_title'}
                        /></Box>
                        <Box className={classes.indicationContent}><FormattedMessage
                            id={`job_creation_funnel.job_offer_generator.${jobType}_indications`}
                        /></Box>
                        <FormattedMessage id="job_creation_funnel.job_offer_generator.describe">
                            {placeholder => (
                                <TextField
                                    className={`${classes.textField} ${classes.input}`}
                                    id="promptInput"
                                    variant="outlined"
                                    placeholder={placeholder}
                                    multiline
                                    minRows={7}
                                    maxRows={7}
                                    value={prompt}
                                    onChange={handleChange}
                                />
                            )}
                        </FormattedMessage>
                            <Box className={classes.wordCount}> {wordsLeft} / {maxWords} </Box>
                        <Box className={classes.example}><FormattedMessage
                            id={`job_creation_funnel.job_offer_generator.examples_${jobType}`}
                        /></Box>
                        <Chip
                            label={<FormattedMessage id={`job_creation_funnel.job_offer_generator.${jobType}_prompt_example1`}/>}
                            className={classes.exampleEntry}
                        />
                        <Chip
                            label={<FormattedMessage id={`job_creation_funnel.job_offer_generator.${jobType}_prompt_example2`}/>}
                            className={classes.exampleEntry}
                        />
                        <Chip
                            label={<FormattedMessage id={`job_creation_funnel.job_offer_generator.${jobType}_prompt_example3`}/>}
                            className={classes.exampleEntry}
                        />
                    </Box>
                    <Box className="loader"></Box>
                    <FunnelSubmitBar handleSubmit={handleSubmit} isLoading={isLoading}/>
                </FunnelContentWrapper>
            </JobCreationFunnelWrapper>
        </PageWrapper>
    )
}
export default JobListingGenerator
