import React from 'react'

import NavigationDropDownFirstJobs from './NavigationDropDowns/NavigationDropDownFirstJobs/NavigationDropDownFirstJobs'
import NavigationDropDownInternships from './NavigationDropDowns/NavigationDropDownInternships/NavigationDropDownInternships'
import NavigationDropDownStudentJobs from './NavigationDropDowns/NavigationDropDownStudentJobs/NavigationDropDownStudentJobs'
import NavigationDropDownKots from './NavigationDropDowns/NavigationDropDownKots/NavigationDropDownKots'
import useNavigationTypeToItemType from 'hooks/useNavigationTypeToItemType'

const NavigationDropDownMenu = (props) => {
    const { type } = props
    const itemType = useNavigationTypeToItemType(type)

    const renderFactoryDropDownMenu = () => {
        switch (type) {
        case 'student-jobs':
            return <NavigationDropDownStudentJobs {...props} itemType={itemType} />
        case 'first-jobs':
            return <NavigationDropDownFirstJobs {...props} itemType={itemType} />
        case 'internships':
            return <NavigationDropDownInternships {...props} itemType={itemType} />
        case 'student-rooms':
            return <NavigationDropDownKots {...props} itemType={itemType} />
        }
    }

    return (<>
        {renderFactoryDropDownMenu()}
    </>)
}

export default NavigationDropDownMenu
