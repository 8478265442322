import React, { useEffect, useState } from 'react'
import postscribe from 'postscribe'
import AnalyticsHelper from 'assets/AnalyticsHelper'

import css from './adbanner.scss'
import css_mobile from './adbanner_mobile.scss'
import Box from '@material-ui/core/Box'

import { Image, Transformation } from 'cloudinary-react'
import RouteMap from 'assets/RouteMap'
import AdsAPI from 'api/AdsAPI'

const AdBanner = (props) => {
    const { device, ads, place, noMarginBottom, width, style, responsiveBanner } = props

    const [selectedAd, setSelectedAd] = useState(null)
    const locale = RouteMap.GetLocaleFromUrl()

    const renderIframe = (ad) => {
        return <div dangerouslySetInnerHTML={{ __html: ad.iframe_tag }}></div>
    }

    const renderJavascript = (ad) => {
        /* Example logic to render JS if needed */
    }

    const getFilteredAds = () => {
        if (!ads || ads.length === 0) return []
        return ads
            .filter(ad => ad.places.includes(place))
            .filter(ad => locale === ad.langage)
    }

    useEffect(() => {
        const filteredAds = getFilteredAds()
        if (device !== (document.documentElement.clientWidth < 576 ? 'mobile' : 'desktop') || filteredAds.length === 0) {
            return
        }
        const ad = filteredAds[Math.floor(Math.random() * filteredAds.length)]
        setSelectedAd(ad)
        if (ad) {
            sendAdBannerEvent(ad, 'AdBanner Impression')
            increaseAdShowCounter(ad)
        }
    }, [])

    useEffect(() => {
        if (!selectedAd) return

        if (document.getElementById('dcmads') !== null) {
            postscribe('#dcmads', "<script src='https://www.googletagservices.com/dcm/dcmads.js'></script>")
        }
    }, [selectedAd])

    const increaseAdShowCounter = (ad) => {
        AdsAPI.IncreaseAdShowcounter({
            ad_id: ad.id
        })
    }

    const sendAdBannerEvent = (ad, event) => {
        const label = `${ad.campaign_name} - ${ad.langage} - ${place}`
        AnalyticsHelper.sendGTMEvent(
            event,
            AnalyticsHelper.constructBannerAnalyticsAttributes(label)
        )
    }

    const marginBottom = noMarginBottom ? 0 : '20px'
    
    return (
        <>
            {selectedAd && (
                <Box className={'ad-bannerdse ' + (window.innerWidth < 576 ? 'mobile' : 'desktop')} style={style}>
                    <div className="ad-banner-content">
                        <a
                            href={selectedAd.url}
                            target="_blank"
                            rel='noreferrer'
                            onClick={() => sendAdBannerEvent(selectedAd, 'AdBanner Click')}
                        >
                            {selectedAd.isMultiHtml && (
                                <div style={{ marginBottom }}>
                                    {renderIframe(selectedAd)}
                                    {renderJavascript(selectedAd)}
                                </div>
                            )}
                            {selectedAd.isHtml && (
                                <div style={{ marginBottom }} dangerouslySetInnerHTML={{ __html: selectedAd.html }} />
                            )}
                            {selectedAd.isImage && (
                                <div style={{ marginBottom }}>
                                    <Image
                                        cloudName="studentbe"
                                        publicId={selectedAd.cloudinary_key}
                                        alt={selectedAd.cloudinary_key}
                                        width="100%"
                                    >
                                        <Transformation
                                            width="auto"
                                            flags="progressive"
                                            fetchFormat="auto"
                                            quality="auto:good"
                                            crop="scale"
                                            dpr="auto"
                                        />
                                    </Image>
                                </div>
                            )}
                        </a>
                    </div>
                </Box>
            )}
        </>
    )
}

export default AdBanner
