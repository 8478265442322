import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import RecruitingCompaniesForUser from 'pages/user/PersonalDashboard/components/RecruitingCompaniesForUser/RecruitingCompaniesForUser'
import RecruitingCompanies from 'components/RecruitingCompanies/RecruitingCompanies'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { useStyles } from '../Lost_style'
import { Hidden } from '@material-ui/core'

const LostCompanyPage = (props) => {
    const { user } = props
    const classes = useStyles()

    return (
        <Container maxWidth="md" className={classes.aboutLostCompanyContainer}>
            <Box className={classes.aboutLostContainer}>
                <Box className={classes.aboutLostContent}>
                    <Typography variant='h1' className={classes.title}>
                        <FormattedMessage id="landing_aboutlost.banner" />
                    </Typography>
                    <Box className={classes.lostInfoContainer}>
                        <Typography variant="h2"><FormattedMessage id="lost.company.does_not_exist_banner" /></Typography>
                    </Box>
                </Box>
                <Hidden smDown>
                    <Box className={classes.aboutLostImg}>
                        <Image
                            cloudName="studentbe"
                            publicId='assets/404_meme_3'
                            alt='404-lost-on-student-be'
                        >
                            <Transformation q_auto='eco'/>
                        </Image>
                    </Box>
                </Hidden>
            </Box>
            <Box className={classes.recommendationBox}>
                {user
                    ? <RecruitingCompaniesForUser user={user} />
                    : <RecruitingCompanies />
                }
            </Box>
        </Container>
    )
}

export default LostCompanyPage
