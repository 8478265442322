const useJobIconColor = (jobType) => {
    switch (jobType) {
    case 'student_job': return 'var(--background-yellow)'
    case 'internship': return 'var(--background-blue)'
    case 'first_job': return 'var(--background-green)'
    case 'kot': return 'var(--background-orange)'
    }
}

export default useJobIconColor
