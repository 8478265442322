/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'

import { useStyles } from './LoginRegisterPage_style'
import { Box } from '@material-ui/core'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import MultiStepRegister from '../Register/MultiStepRegister/MultiStepRegister'
import RouteMap from 'assets/RouteMap'
import SessionAPI from 'api/SessionAPI'
import URLParamsHandler from 'assets/URLParamsHandler'
import {
    EBOOK_DOWNLOAD, FAVOURITE, KOT_SHOW,
    JOB_APPLICATION, SAVE_FILTERS, JOB_SHOW,
    JOB_FAIRS, PROMOTION, EMPLOYER_BUYING_CREDITS,
    MY_BS_REGISTRATION
} from 'assets/AuthentificationHelper'
import AuthentificationContainer from '../AuthentificationContainer/AuthentificationContainer'

axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()

const LoginRegisterPage = (props) => {
    const classes = useStyles()

    const userTypeFromParams = URLParamsHandler.parseURLParams().userType
    const modFromParams = URLParamsHandler.parseURLParams().mod
    const loginProviderFromParams = URLParamsHandler.parseURLParams().loginProvider
    const emailFromParams = URLParamsHandler.parseURLParams().email
    const showProviderError = URLParamsHandler.parseURLParams().showProviderError === 'true'

    const [showLoginProviderModal, setShowLoginProviderModal] = useState(false)
    const [loginProvider, setLoginProvider] = useState(loginProviderFromParams || 'email')
    const [state, setState] = useState({
        mod: modFromParams || 0,
        loginUserEmail: emailFromParams,
        loginUserPassword: '',
        rememberMe: false,
        termsAccepted: false,
        firstName: '',
        lastName: '',
        signUpUserEmail: '',
        signUpUserPassword: '',
        signUpErrors: {
            email: '',
            password: ''
        },
        loginError: {},
        captchaSuccessful: true,
        userStoredLocation: null,
        userType: userTypeFromParams,
        responsiveSelection: null,
        showPassword: false
    })

    useEffect(() => {
        initUserStoredLocation()

        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id)

            if (!isScriptExist) {
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.src = url
                script.id = id
                script.onload = function () {
                    if (callback) callback()
                }
                document.body.appendChild(script)
            }

            if (isScriptExist && callback) callback()
        }

        loadScriptByURL('recaptcha-key', 'https://www.google.com/recaptcha/api.js?render=6LcNrXsbAAAAAPEXiiPURmk2N2FDGWhaxOi246Hj', function () {
            console.log('Recaptcha script loaded!')
        })
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target
        setState({ ...state, [name]: value })
    }

    const afterLoginAction = (response = null, loginType) => {
        const urlParams = URLParamsHandler.parseURLParams()

        if (loginActionFromOrigin(response?.data?.user, loginType)) {
        } else if (urlParams.redirect) {
            URLParamsHandler.redirectWithURL()
        } else if (state.userStoredLocation) {
            redirectUser()
        } else if (response?.data.redirection_url) {
            window.location.href = response.data.redirection_url
        } else {
            window.location.assign(document.referrer)
        }
    }

    const loginActionFromOrigin = (user, loginType) => {
        AnalyticsHelper.sendGTMEvent(
            'Login',
            AnalyticsHelper.constructLoginAnalyticsAttributes(user, loginType, props.origin)
        )

        switch (props.origin) {
        case EMPLOYER_BUYING_CREDITS:
            props.afterAuthAction()
            return true
        case EBOOK_DOWNLOAD:
            AnalyticsHelper.sendGTMEvent('ebook-login-success')
            props.afterAuthAction()
            return true
        case MY_BS_REGISTRATION:
            props.afterAuthAction()
            return true
        case FAVOURITE:
            window.location.reload()
            return true
        case JOB_FAIRS:
        case KOT_SHOW:
        case JOB_APPLICATION:
        case SAVE_FILTERS:
        case JOB_SHOW:
        case PROMOTION:
            props.afterAuthAction()
            return true
        default:
            return false
        }
    }

    const afterRegisterAction = (loginType) => {
        if (!registerActionFromOrigin(loginType)) {
            setState({ ...state, mod: 1, loginType: loginType })
        }
    }

    const registerActionFromOrigin = (loginType) => {
        AnalyticsHelper.sendGTMEvent(
            'Create Account Started',
            AnalyticsHelper.constructRegisterAnalyticsAttributes(loginType, props.origin)
        )

        switch (props.origin) {
        // ebook_download, kot_show require
        // the register funnel, hence return false to continue process
        case EMPLOYER_BUYING_CREDITS:
            setState({ userType: 'employer' })
            return false
        case EBOOK_DOWNLOAD:
            return false
        case KOT_SHOW:
        case PROMOTION:
            setState({ userType: 'student' })
            return false
        case FAVOURITE:
            window.location.reload()
            return true
        case JOB_FAIRS:
            return false
        case MY_BS_REGISTRATION:
            setState({ userType: 'student' })
            return false
        case JOB_APPLICATION:
            props.afterRegisterAction()
            return true
        case SAVE_FILTERS:
        case JOB_SHOW:
            props.afterAuthAction()
            return true
        default:
            return false
        }
    }

    const logRequest = (event) => {
        event.preventDefault()

        const { loginUserEmail, loginUserPassword, rememberMe } = state

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcNrXsbAAAAAPEXiiPURmk2N2FDGWhaxOi246Hj', { action: 'login' }).then(token => {
                SessionAPI.Login({
                    user: {
                        email: loginUserEmail,
                        password: loginUserPassword,
                        remember_me: rememberMe
                    },
                    recaptcha_response: token,
                    signup_origin: props.origin
                }).then((response) => {
                    if (response.data.user_provider) {
                        setLoginProvider(response.data.provider)
                        setShowLoginProviderModal(true)
                    } else {
                        afterLoginAction(response, 'Student.be')
                    }
                }).catch((error) => {
                    console.log(error)
                    setState({ ...state, loginError: error.response.data.errors })
                })
            })
        })
    }

    const redirectUser = () => {
        window.location.href = state.userStoredLocation
    }

    const signUpRequest = (event) => {
        event.preventDefault()
        const { signUpUserEmail, signUpUserPassword, termsAccepted } = state

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcNrXsbAAAAAPEXiiPURmk2N2FDGWhaxOi246Hj', { action: 'register' }).then(token => {
                SessionAPI.Register({
                    user: {
                        email: signUpUserEmail,
                        password: signUpUserPassword,
                        terms_accepted: termsAccepted,
                        signup_origin: props.origin
                    },
                    recaptcha_response: token
                }).then((_) => {
                    AnalyticsHelper.sendGTMEvent('registration-account', {
                        language: RouteMap.GetLocaleFromUrl()
                    })
                    afterRegisterAction('Student.be')
                }).catch((error) => {
                    setState({ ...state, signUpErrors: error.response.data.errors })
                })
            })
        })
    }

    const logInFacebook = (facebookResponse) => {
        SessionAPI.Login({
            email: facebookResponse.email,
            uid: facebookResponse.userID,
            access_token: facebookResponse.accessToken,
            process: 'facebook'
        }).then((response) => {
            AnalyticsHelper.sendGTMEvent('facebook-login-success')
            afterLoginAction(response, 'Facebook')
        }).catch((error) => {
            if (error.response.data.errors.process === 'facebook-register') {
                signUpFacebook(facebookResponse, true)
            } else {
                setState({ ...state, loginError: error.response.data.errors.email[0] })
            }
        })
    }

    const signUpFacebook = (facebookResponse, isFromLogin = false) => {
        SessionAPI.Register({
            user: {
                email: facebookResponse.email,
                facebook_name: facebookResponse.name,
                uid: facebookResponse.userID,
                access_token: facebookResponse.accessToken,
                process: 'facebook',
                signup_origin: props.origin
            }
        }).then((_) => {
            AnalyticsHelper.sendGTMEvent('facebook-register-success')
            afterRegisterAction('Facebook')
        }).catch((error) => {
            if (isFromLogin) {
                setState({ ...state, loginError: error.response.data.errors.email[0] })
            } else {
                setState({ ...state, signUpErrors: error.response.data.errors })
            }
        })
    }

    const initUserStoredLocation = () => {
        axios({
            url: '/users/get_stored_location'
        }).then((response) => {
            if (response.data.url === props.urls.firstJob || response.data.url === props.urls.studentJob || response.data.url === props.urls.internship) {
                setState({ ...state, userType: 'employer', userStoredLocation: response.data.url })
            }
            if (response.data.url === props.urls.kot) {
                setState({ ...state, userType: 'kot_owner', userStoredLocation: response.data.url })
            }
        }).catch((error) => {
            console.log('An error occurred while initializing userStoredLocation: ' + error)
        })
    }

    const render = () => {
        return (
            <Box className={!props.withoutContainer && classes.loginRegisterPageContainer}>
                {state.mod === 0
                    ? <AuthentificationContainer
                        handleChange={handleChange}
                        logRequest={logRequest}
                        signUpRequest={signUpRequest}
                        state={state}
                        setState={setState}
                        loginTitle={props.loginTitle}
                        loginSubTitle={props.loginSubTitle}
                        showLoginProviderModal={showLoginProviderModal}
                        setShowLoginProviderModal={setShowLoginProviderModal}
                        loginProvider={loginProvider}
                        setLoginProvider={setLoginProvider}
                        showProviderError={showProviderError}
                    />
                    : <MultiStepRegister
                        action={props.action}
                        afterSignInAction={props.afterSignInAction || props.afterAuthAction}
                        jobInfo={props.jobInfo}
                        origin={props.origin}
                        successfulApplyPath={props.successfulApplyPath}
                        urls={props.urls}
                        userStoredLocation={state.userStoredLocation}
                        userType={state.userType}
                        loginType={state.loginType}
                        matchingCompanies={props.matchingCompanies}
                    />
                }
            </Box>
        )
    }

    return (
        render()
    )
}

export default LoginRegisterPage
