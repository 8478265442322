import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 20
    },
    title: {
        fontSize: '14px !important'
    },
    iconBackground: {
        height: 64,
        width: 64,
        borderRadius: '100%',
        backgroundColor: (props) => props.backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    seeAll: {
        borderRadius: '8px',
        padding: '10px 14px',
        cursor: 'pointer',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.bg.greyPale
        },
        '& svg': {
            marginLeft: 4
        }
    }
}))
