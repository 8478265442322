import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './ShareModalView_style'
import { FormattedMessage, injectIntl } from 'react-intl'

const ShareModalView = (props) => {
    const { showModal, closeModal, infos } = props
    const { formatMessage } = props.intl
    const classes = useStyles()

    const getShareSubject = () => {
        var subjectType
        switch (infos.type) {
        case 'company':
            subjectType = formatMessage({ id: 'share_popup.share_type.company' })
            break
        case 'article':
            subjectType = formatMessage({ id: 'share_popup.share_type.article' })
            break
        case 'first_job':
        case 'internship':
        case 'student_job':
            subjectType = formatMessage({ id: 'share_popup.share_type.job' })
            break
        case 'kot':
            subjectType = formatMessage({ id: 'share_popup.share_type.kot' })
            break
        }
        return subjectType
    }

    const getBodyText = () => {
        return `${formatMessage({ id: 'share_popup.share_text_part_one' })} ${getShareSubject()} ${formatMessage({ id: 'share_popup.share_text_part_two' })}`
    }

    const getTitleText = () => {
        if (infos.type === 'article') {
            return <FormattedMessage id='share_popup.share_article' />
        } else if (infos.type === 'company') {
            return <FormattedMessage id='share_popup.share_company_page' />
        } else {
            return <FormattedMessage id='share_popup.share_offer' />
        }
    }

    return (
        <Dialog
            PaperProps={{ className: classes.dialogContainer }}
            maxWidth='xs'
            open={showModal}
            onClose={closeModal}
            disableScrollLock={true}
            aria-labelledby='responsive-dialog-delete'
            fullWidth
        >
            <DialogTitle onClose={closeModal} className={classes.dialogTitle} disableTypography>
                <Typography variant='h2'>
                    {getTitleText()}
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={closeModal}
                    className={classes.closeIcon}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box m={2}>
                    <Button
                        className={classes.button}
                        fullWidth
                        target='_blank'
                        href={`https://www.facebook.com/sharer.php?u=${infos.url}`}
                        rel='noopener'
                        startIcon={<IconFactory icon='facebook' />}
                    >
                        Facebook
                    </Button>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Button
                            className={classes.button}
                            target='_blank'
                            href={`fb-messenger://share/?app_id=1313214995677721&link=${infos.url}`}
                            rel='noopener'
                            fullWidth
                            startIcon={<IconFactory icon='messenger' />}
                        >
                            Messenger
                        </Button>
                        <Button
                            className={classes.button}
                            target='_blank'
                            href={`whatsapp://send?text=${getBodyText()} : ${infos.url}`}
                            rel='noopener'
                            fullWidth
                            startIcon={<IconFactory icon='whatsapp' />}
                        >
                            Whatsapp
                        </Button>
                    </Hidden>
                    {/* <Button
                        className={classes.button}
                        target='_blank'
                        href={`https://twitter.com/intent/tweet?url=${infos.url}&text=${getBodyText()}&hashtags=student.be,jobs`}
                        rel='noopener'
                        fullWidth
                        startIcon={<IconFactory icon='twitter' />}
                    >
                        Twitter
                    </Button> */}
                    <Button
                        className={classes.button}
                        target='_blank'
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${infos.url}`}
                        rel='noopener'
                        fullWidth
                        startIcon={<IconFactory icon='linkedin' />}
                    >
                        LinkedIn
                    </Button>
                    <Button
                        className={classes.button}
                        target='_blank'
                        href={`mailto:?subject=${getBodyText()}&body=${infos.url}`}
                        rel='noopener'
                        fullWidth
                        startIcon={<IconFactory icon='mail' />}
                    >
                        Email
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default injectIntl(ShareModalView)
