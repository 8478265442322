import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, useTheme } from '@material-ui/core'
import isEmpty from 'lodash.isempty'

import { useStyles } from './CompanyOtherJobs_style'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import JobCardMinimalist from '../../cards/JobCard/JobCardMinimalist'

const CompanyOtherJobs = (props) => {
    const classes = useStyles()
    const { jobs, amount = 3, companyName, companyUrl, currentJobType } = props
    const [jobsToRender, setJobsToRender] = useState([])
    const theme = useTheme()

    useEffect(() => {
        if (isEmpty(jobs)) return

        const filteredJobs = jobs.sort((a, b) => {
            if (a.type === currentJobType && b.type !== currentJobType) return -1
            if (a.type !== currentJobType && b.type === currentJobType) return 1

            return 0
        })
        setJobsToRender(filteredJobs.slice(0, amount))
    }, [])

    if (!jobs || jobs?.length === 0) return null

    return (
        <Box className={classes.CompanyOtherJobsContainer}>
            <Box className={classes.titleContainer}>
                <Box className={classes.iconContainer}>
                    <SVGFactory name='lightning' width='14px' height='14px' />
                </Box>
                <Typography className={classes.title} variant='h1' component='h2'>
                    <FormattedMessage id='job_show.other_company_jobs.title' /> {companyName}
                </Typography>
            </Box>
            <Box className={classes.jobsContainer}>
                {jobsToRender.map(job => <JobCardMinimalist job={job} key={job.id} />)}
            </Box>
            <Box className={classes.linkContainer}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={companyUrl}
                    className={classes.link}
                >
                    <Typography variant='h1' component='p' className={classes.linkText}>
                        <FormattedMessage id='job_show.other_company_jobs.more_link' /> {companyName}
                    </Typography>
                    <Box className={classes.linkIconContainer}>
                        <IconFactory
                            icon='arrow-right'
                            color={theme.palette.fg.white}
                            width='10px'
                            height='10px'
                        />
                    </Box>
                </RouteMapLink>
            </Box>
        </Box>
    )
}

export default CompanyOtherJobs
