import React from 'react'

import { useStyles } from './NavigationDropDownContainer_style'
import { Box, Popper, Grow, Paper } from '@material-ui/core'

import NavigationDropDownArticles from '../NavigationDropDownArticles/NavigationDropDownArticles'

const NavigationDropDownContainer = (props) => {
    const classes = useStyles()
    const {
        children,
        isMenuModalOpen, openModal, type
    } = props

    return (
        <Popper
            open={isMenuModalOpen}
            onMouseOver={openModal}
            className={classes.popperDropDown}
            transition disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} >
                    <Paper className={classes.paperDropDown}>
                        <Box className={classes.dropDownContainer}>
                            {children}
                            <NavigationDropDownArticles type={type}/>
                        </Box>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default NavigationDropDownContainer
