import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        width: '100%',
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        backgroundPosition: '0px 174px',
        padding: '16px 16px 27px 16px',
        backgroundColor: theme.palette.bg.purple,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    logo: {
        width: '143px',
        height: '44px'
    },

    iconsContainer: {
        display: 'flex',
        gap: 10
    },
    button: {
        padding: '0px',
        '&:hover': {
            background: 'transparent'
        }
    },

    burgerIcon: {
        fontSize: '29px !important',
        color: theme.palette.fg.white
    }
}))
