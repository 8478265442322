import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewsIndex_style'
import { Box, Container, Typography, Chip, Hidden } from '@material-ui/core'

import { NUMBER_TREND_ARTICLES, NUMBER_RECENT_ARTICLES, NUMBER_CLASSIC_ARTICLES, NUMBER_TOTAL_TREND_ARTICLES } from '../settings'
import ArticleAPI from 'api/ArticleAPI'
import ArticleListMinimalCard from 'shared/cards/NewsCard/ArticleListMinimalCard/ArticleListMinimalCard'
import ArticlesList from '../shared/ArticlesList/ArticlesList'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'
import HomeSearch from 'components/HomeSearch/HomeSearch'
import HorizontalCardsSlider from 'shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import LottieFactory from 'icons/LottieFactory/LottieFactory'
import NewsSearchBar from '../shared/NewsSearchBar/NewsSearchBar'
import PromotionAPI from 'api/PromotionAPI'
import PromotionCard from 'shared/cards/PromotionCard/PromotionCard'
import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import SEOBlock from 'pages/ItemIndexMain/SEOBlock/SEOBlock'
import TrendMainArticleCard from 'shared/cards/NewsCard/TrendMainArticleCard/TrendMainArticleCard'

const NewsIndex = (props) => {
    const classes = useStyles()
    const [recentArticles, setRecentArticles] = useState([])
    const [mostReadArticles, setMostReadArticles] = useState([])
    const [classicArticles, setClassicArticles] = useState([])
    const [promotions, setPromotions] = useState([])
    const [trendMainArticles, setTrendMainArticles] = useState(null)
    const [trendArticles, setTrendArticles] = useState(null)

    const newsCategories = CollectionHandler.Get('newsIndexCategories')

    useEffect(() => {
        loadRecentArticles()
        loadMostReadArticles()
        loadClassicArticles()
        loadPromotions()
    }, [])

    useEffect(() => {
        setTrendMainArticles(mostReadArticles[0])
        setTrendArticles(mostReadArticles.slice(-NUMBER_TREND_ARTICLES))
    }, [mostReadArticles])

    // *** ASYNC ARTICLES & PROMOTIONS LOAD ***
    const loadRecentArticles = () => {
        ArticleAPI.GetRecentArticles({
            count: NUMBER_RECENT_ARTICLES
        }).then((response) => {
            setRecentArticles(response.data)
        })
    }

    const loadMostReadArticles = () => {
        ArticleAPI.getMostReadArticles({
            number_of_articles: NUMBER_TOTAL_TREND_ARTICLES
        }).then((response) => {
            setMostReadArticles(response.data)
        })
    }

    const loadClassicArticles = () => {
        ArticleAPI.getClassicArticles({
            number_of_articles: NUMBER_CLASSIC_ARTICLES
        }).then((response) => {
            setClassicArticles(response.data)
        })
    }

    const loadPromotions = () => {
        PromotionAPI.filterPromotion({

        }).then((response) => {
            setPromotions(response.data.promotions)
        })
    }
    // *** END BLOCK ASYNCS ***

    const createCategoryCase = (category) => {
        const categoryTranslated = CollectionHandler.Translate('articleCategoryCollection', category.name)
        return (
            <RouteMapLink redirectionUrl={`${RouteMap.Page('student-life')}categories/${CollectionHandler.categoryToValueTransformation(categoryTranslated)}/`}>
                <Chip
                    label={categoryTranslated}
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                />
            </RouteMapLink>
        )
    }

    return (
        <Box className={classes.pageContainer}>
            <Hidden mdDown>
                <Box className={classes.lottieIconOne}>
                    <LottieFactory lottie='smiley' />
                </Box>
                <Box className={classes.lottieIconTwo}>
                    <LottieFactory lottie='star' delay={1000}/>
                </Box>
            </Hidden>
            <FiltersViewWrapper titleId='news_index.title'>
                <Box className={classes.filterViewContent}>
                    <NewsSearchBar />
                </Box>
            </FiltersViewWrapper>
            <Container maxWidth='lg' className={classes.quickFiltersContainer} disableGutters>
                <Typography className={classes.quickFiltersTitle} variant='h4'>
                    <FormattedMessage id='news_index.quick_filters_title' />
                </Typography>
                {newsCategories.map((category) => createCategoryCase(category))}
            </Container>
            <Container maxWidth='lg' className={classes.pageContent} disableGutters>
                {trendMainArticles && trendArticles &&
                    <Box className={classes.trendArticesSection}>
                        <TrendMainArticleCard article={trendMainArticles} />
                        <Box className={classes.articleMinimalCardsContainer}>
                            {trendArticles.map((article, index) => <ArticleListMinimalCard article={article} key={index} />)}
                        </Box>
                    </Box>
                }
                <Box className={classes.recentArticlesSection}>
                    <ArticlesList articles={recentArticles} />
                </Box>
                <HomeSearch />
                <Box className={classes.classicArticlesSection}>
                    <Typography className={classes.classicArticlesSectionTitle} variant='h1' component='h2'>
                        <FormattedMessage id='news_index.classic_articles.title' />
                    </Typography>
                    <ArticlesList articles={classicArticles} />
                </Box>
            </Container>
            <Container maxWidth='lg' disableGutters>
                <HorizontalCardsSlider
                    shift={500}
                    title={<FormattedMessage id='news_index.deals' />}
                >
                    {promotions.map((promo, index) => {
                        return (
                            <Box className={classes.promotionCardContainer} key={index}>
                                <PromotionCard promotion={promo}/>
                            </Box>
                        )
                    })}
                </HorizontalCardsSlider>
            </Container>
            <SEOBlock
                type='article'
                selectedLocationTag={props.selectedArticleTagObject}
            />
        </Box>
    )
}

export default NewsIndex
