import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    filters: {
        display: 'flex',
        gap: 12,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            width: '100%'
        }
    },
    filterBox: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    inputProps: {
        fontFamily: "'GintoNormal-Medium', sans-serif",
        padding: 0,
        paddingRight: '0px !important',
        width: '100%',
        height: '100%',
        fontSize: 14,
        '&::placeholder': {
            color: 'black',
            opacity: 1
        }
    },
    placeholderText: {
        fontFamily: "'GintoNormal-Regular', sans-serif !important",
        fontSize: 14,
        fontStyle: 'normal',
        color: 'var(--dark-beige)'
    },
    textFilterContainer: {
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    valueContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 14px !important',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 400
        }
    },
    tagText: {
        fontSize: 14
    },
    tagAvatar: {
        fontFamily: "'GintoNormal-Bold', sans-serif !important",
        fontSize: 14,
        width: 20,
        height: 18,
        color: 'var(--beige)',
        backgroundColor: 'black',
        marginLeft: 8
    },
    iconContainer: {
        marginRight: '8px',
        height: 19
    },
    dropdownContainer: {
        marginLeft: 20.7
    },
    cityFilterDropdown: {
        zIndex: 2000,
        backgroundColor: 'white',
        borderRadius: '11px',
        boxShadow: 'var(--shadow-paper)',
        marginTop: '10px',
        maxWidth: 400
    },
    cityFilterDropdownBox: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    selectPlaceholder: {
        flexGrow: 1
    },
    baseInput: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 12,
        backgroundColor: 'white',
        textTransform: 'none',
        '& span': {
            justifyContent: 'space-between'
        },
        '&:hover': {
            backgroundColor: 'var(--light-grey)'
        }

    },
    inputBackground: {
        backgroundColor: 'var(--beige) !important',
        borderRadius: 50,
        '&:hover': {
            backgroundColor: 'var(--beige) !important'
        }
    },
    textFilterInput: {
        '&::placeholder': {
            fontFamily: "'GintoNormal-Regular', sans-serif !important",
            fontSize: 14,
            fontStyle: 'normal',
            color: 'var(--dark-beige)'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
            width: '100%'
        }
    },
    selectSearchbar: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'var(--beige)',
        padding: '10px 14px',
        margin: '12px 0 12px 20px',
        borderRadius: 50
    },
    selectSearchbarV2: {
        cursor: 'text',
        width: 250,
        display: 'flex',
        backgroundColor: 'var(--beige)',
        padding: 16,
        borderRadius: 8,
        height: 50,
        alignItems: 'center',
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    inputBase: {
        padding: 0
    },
    selectedTagsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 20px 0 0',
        marginTop: 10
    },
    selectedLocationTag: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 50,
        backgroundColor: 'var(--red)',
        color: 'white',
        padding: '0 16px',
        marginRight: 8,
        marginBottom: 8,
        '&:hover': {
            opacity: 0.8
        }
    },
    selectedLocationTagSmall: {
        fontSize: 12,
        height: 25,
        padding: '6px 8px'
    },
    unSelectedToggleTag: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 50,
        backgroundColor: 'var(--beige)',
        padding: '6px 22px',
        marginRight: 8,
        marginBottom: 8,
        '&:hover': {
            opacity: 0.8
        }
    },
    tagCross: {
        marginRight: 10
    },
    menuItem: {
        padding: '6px 20px'
    },
    tagList: {
        marginLeft: 20
    },
    checkbox: {
        marginRight: 3,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    formControl: {
        width: '100%'
    },
    filterTagList: {
        marginTop: 10
    },
    boxInputSearchCity: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'sticky',
        zIndex: 9999,
        top: 0,
        borderBottom: '1px solid #EFEAE5'
    },
    boxInputSearchCityV2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'sticky',
        borderBottom: '1px solid #EFEAE5',
        paddingLeft: 20
    },
    closeIconButton: {
        marginLeft: 8,
        marginRight: 8,
        height: 'fit-content',
        '& svg': {
            width: 18
        }
    },
    searchIcon: {
        marginRight: 8
    },
    quickFilterContainer: {
        position: 'relative', // This property will be removed once all quick filters are rebranded
        padding: '24px 0px 20px',
        [theme.breakpoints.up('md')]: {
            padding: '18px 21px 6px',
        },
        [theme.breakpoints.up(1228)]: {
            paddingLeft: '45px',
            paddingRight: '45px',
        },
        [theme.breakpoints.up(1366)]: {
            padding: '20px 0px 20px',
        },
    },
    quickFiltersInner: {
        position: 'relative',
        width: '100%',
    },
    quickFilters: {
        display: 'flex',
        gap: '12px',
        overflowX: 'auto',
        scrollSnapType: 'x',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none', /* for Firefox */
        '&::-webkit-scrollbar': {
            display: 'none' /* for Chrome, Safari, and Opera */
        },
        maskImage: 'linear-gradient(90deg, transparent, black 10%, black 90%, transparent)',
        [theme.breakpoints.down('sm')]: {
            gap: 4,
            maskImage: 'none'
        }
    },
    quickFilterOption: {
        width: 75,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 4,
        margin: '12px 0px',
        cursor: 'pointer',
        color: 'var(--black)',
        scrollSnapAlign: 'start',
        textAlign: 'center',
        '&:hover': {
            '& $underlineInvisible': {
                opacity: 0.7
            }
        }
    },
    quickFilterText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        height: 40,
        minWidth: 90,
        maxWidth: 'fit-content',
        '&:hover .containerIcon': {
            backgroundColor: 'red'
        }
    },
    '.quickFilterText:hover': {
        containerIcon: {
            backgroundColor: 'red'
        }
    },
    selected: {
        backgroundColor: 'var(--foreground-purple) !important'
    },
    optionUnderline: {
        height: 3,
        width: '100%',
        borderRadius: 2,
        background: 'var(--red)'
    },
    underlineInvisible: {
        opacity: 0
    },
    underlineVisible: {
        opacity: 1
    },
    // V2 Quickfilter
    containerIcon: {
        zIndex: '0',
        height: '42px',
        width: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--background-silver)',
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: 'var(--foreground-purple)'
        }
    },
    containerIconImpact: {
        backgroundColor: 'var(--background-green)'
    }
}))
