import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { injectIntl } from 'react-intl'

import { Box, Typography, Container, useTheme } from '@material-ui/core'

import { useStyles } from './JobShowBannerDesktopSticky_style'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import ApplyButton from '../../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'

const JobShowBannerDesktopSticky = (props) => {
    const { job, user, unavailable, intl } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className={classes.stickiBannerWrapper}>
            <Container className={classes.stickyBannerContainer} maxWidth='lg' disableGutters>
                <Box className={classes.leftContainer}>
                    <Box className={classes.companyLogoContainer}>
                        <RouteMapLink
                            showItemType='company'
                            showItemUrl={job.company_url}
                            className={classes.logoBox}
                        >
                            <Image
                                className={classes.logo}
                                cloudName="studentbe"
                                publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                                alt={`${job.company_logo_cloudinary_key} logo`}
                            >
                                <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                            </Image>
                        </RouteMapLink>
                    </Box>
                    <Box className={classes.textContainer}>
                        <Typography component='h1' className={classes.jobTitle}>
                            {job.title}
                        </Typography>
                        <Box className={classes.jobInfoContainer}>
                            {
                                job?.city && <Box className={classes.locationContainer}>
                                    <IconFactory icon='location-pin' fontSize='12px' color={theme.palette.fg['black90Opacity']} />
                                    <Typography
                                        component='p'
                                        className={classes.infoText}
                                    >
                                        {job.city}
                                    </Typography>
                                </Box>
                            }
                            {job?.job_tags?.length > 0 &&
                                <Box className={classes.tagsContainer}>
                                    <IconFactory icon='clock' fontSize='12px' color={theme.palette.fg['black90Opacity']} />
                                    <Typography
                                        component='p'
                                        className={classes.infoText}
                                    >
                                        {job.job_tags.map(tag => intl.formatMessage({ id: `job_card.tags.${tag}` })).join(', ').replace(/_/g, ' ')}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
                {!unavailable &&
                    <Box className={classes.rightContainer}>
                        <Box className={classes.iconButtonsContainer}>
                            <FavoriteAction
                                userType={user?.type}
                                type={job.type}
                                id={job.id}
                                item={job}
                                size='20px'
                                padding='0px'
                            />
                            <ShareAction
                                infos={{
                                    title: job.title,
                                    url: window.location.href,
                                    type: job.type,
                                    shareText: 'transl_internship.share_button'
                                }}
                                userType={user?.type}
                                size='20px'
                                padding='0px'
                            />
                        </Box>
                        <Box className={classes.buttonsContainer}>
                            <ApplyButton
                                containerClass={classes.applyButtonContainer}
                                buttonsClass={classes.applyButton}
                                job={job}
                                user={user}
                                unavailable={unavailable}
                                buttonSize='small'
                            />
                        </Box>
                    </Box>
                }
            </Container>
        </Box>
    )
}

export default injectIntl(JobShowBannerDesktopSticky)
