import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box, Typography, Button } from '@material-ui/core'

import { useStyles } from './NavigationDropDownFilterCard_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const NavigationDropDownFilterCard = (props) => {
    const classes = useStyles()
    const { filter, type } = props

    const buildRouteMapLinkProps = () => {
        switch (type) {
        case 'student-jobs':
        case 'student-rooms':
            return { location: filter.value_name }
        case 'first-jobs':
        case 'internships':
            return { domain: filter.value_name }
        }
    }

    return (
        <RouteMapLink
            itemType={type}
            {...buildRouteMapLinkProps()}
        >
            <Button className={classes.fieldButton}>
                <Box className={classes.fieldContainer}>
                    <Box className={classes.cityContainer}>
                        <Typography variant='h6' component='div' align='left' className={classes.cityName}>
                            {filter.name}
                        </Typography>
                    </Box>
                    <Box className={classes.openJobsContainer}>
                        <Typography variant='subtitle2' component='div' className={classes.openJobs}>
                            {filter.open} <FormattedMessage id={type === 'student-rooms' ? 'page_footer_navbar_data.open_kots' : 'page_footer_navbar_data.open_jobs'} />
                        </Typography>
                        <Box className={classes.linkIconContainer}>
                            <IconFactory
                                icon='arrow-right'
                                color={'var(--background-white)'}
                                width='10px'
                                height='10px'
                            />
                        </Box>
                    </Box>
                </Box>
            </Button>
        </RouteMapLink>
    )
}

export default NavigationDropDownFilterCard
