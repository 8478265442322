import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../NavigationDropDowns/NavigationDropDowns_style'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationLinkItems = (props) => {
    const classes = useStyles()
    const { type, itemType } = props

    return (
        <Box className={classes.dropdownSeeMoreLink}>
            <RouteMapLink page={type} className={classes.seeAll}>
                <Typography variant='h3' component='div' color='secondary'>
                    <FormattedMessage id="page_footer_navbar_data.see_all_of_type" values={{ type_name: <FormattedMessage id={`type.${itemType}.plural`} /> }} />
                </Typography>
                <IconFactory icon='arrow-right' fontSize={13} color={'var(--background-purple)'} />
            </RouteMapLink>
        </Box>
    )
}

export default NavigationLinkItems
