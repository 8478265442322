import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    showMoreButtonBox: {
        display: 'flex',
        justifyContent: 'center'
    },
    showMoreButton: {
        [theme.breakpoints.up('sm')]: {
            width: 240
        }
    },
    alertButton: {
        width: '100%'
    }
}))
