import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './NavigationDropDownTitle_style'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import { FormattedMessage } from 'react-intl'
import useJobIconColor from 'hooks/useJobIconColor'
import useNavigationTypeToItemType from 'hooks/useNavigationTypeToItemType'

const NavigationDropDownTitle = (props) => {
    const { type } = props
    const itemType = useNavigationTypeToItemType(type)
    const backgroundColor = useJobIconColor(itemType)
    const classes = useStyles({ backgroundColor })

    return (
        <Box className={classes.dropDownArticlesContainer}>
            <Box className={classes.titleContainer}>
                <Box className={classes.iconBackground}>
                    <SVGFactory name={itemType} width="35" height="35" />
                </Box>
                <Box className={classes.titleText}>
                    <Typography style={{ fontFamily: 'GreedStandard-Bold', textTransform: 'uppercase', fontSize: 24, lineHeight: 1 }}>
                        <FormattedMessage id='page_footer_navbar_data.popular' />
                    </Typography>
                    <Typography style={{ fontFamily: 'GreedStandard-Regular', textTransform: 'uppercase', fontSize: 24, lineHeight: 1 }}>
                        <FormattedMessage id={`type.${itemType}.plural`} />
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default NavigationDropDownTitle
