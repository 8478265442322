import React, { useRef, useState } from 'react'
import { useStyles } from '../Searchbar_v2_style'
import { Box, Hidden, Container, Typography } from '@material-ui/core'
import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterArrows from '../shared/QuickFilterArrows/QuickFilterArrows'
import QuickFilterTag from '../shared/QuickFilterTag/QuickFilterTag'
import { FormattedMessage } from 'react-intl'

const CompanyQuickFilters = (props) => {
    const classes = useStyles()
    const { filters, setFilters } = props
    const quickFilterCompanyCollection = CollectionHandler.Get('quickFilterCompanyCollection')
    const quickFiltersRef = useRef(null)

    return (
        <Container className={classes.quickFilterContainer} maxWidth='lg' disableGutters>
            <Box className={classes.quickFiltersInner}>
                <Box className={classes.quickFilters} ref={quickFiltersRef}>
                    {quickFilterCompanyCollection.map(quickFilterOption => {
                        return (
                            <QuickFilterTag
                                key={quickFilterOption.value}
                                filters={filters}
                                setFilters={setFilters}
                                quickFilterOption={quickFilterOption}
                                quickFilterSelection={filters.quickFilter}
                                page='companies'
                            />
                        )
                    })}
                    <Hidden smDown>
                        <QuickFilterArrows quickFiltersRef={quickFiltersRef} />
                    </Hidden>
                </Box>
            </Box>
        </Container>
    )
}

export default CompanyQuickFilters
