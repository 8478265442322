import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'


import { useStyles } from './CompanyRecentJobs_style'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import Button from 'shared/components/Button/Button'
import JobCardMinimalist from '../../cards/JobCard/JobCardMinimalist'

const CompanyRecentJobs = (props) => {
    const classes = useStyles()
    const { jobs, amount = 2, handleClick } = props
    const [jobsToRender, setJobsToRender] = useState([])

    useEffect(() => {
        setJobsToRender(jobs?.slice(0, amount))
    }, [])

    return (
        <Box className={classes.CompanyRecentJobsContainer}>
            <Box className={classes.titleContainer}>
                <Box className={classes.iconContainer}>
                    <SVGFactory name='lightning' width='14px' height='14px' />
                </Box>
                <Typography className={classes.title} variant='h1' component='h2'>
                    <FormattedMessage id='job_show.recent_company_jobs.title' />
                </Typography>
            </Box>
            <Box className={classes.jobsContainer}>
                {jobsToRender.map(job =>
                    <JobCardMinimalist job={job} key={job.id} />)
                }
            </Box>
            <Box className={classes.linkContainer}>
                <Button onClick={handleClick} size='small' variant='cta-primary' className={classes.customButton}>
                    <FormattedMessage id='button.see_more_jobs' />
                </Button>
            </Box>
        </Box>
    )
}

export default CompanyRecentJobs
