import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        padding: 20,
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: 'var(--border-radius)',
        background: 'white'
    },
    dialogTitleBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialogcontentBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    title: {
        fontSize: '24px !important',
        padding: '0 24px'
    },
    subTitle: {
        marginBottom: 16
    },
    forgotPasswordLinkText: {
        textDecoration: 'underline',
        marginTop: 8
    },
    passwordForgotBox: {
        marginTop: 24,
        marginBottom: 24
    }
}))
