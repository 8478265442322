import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { useStyles } from '../Lost_style'

const LostPage = (props) => {
    const classes = useStyles()

    const usefulPages = [
        { page: '/', title: 'acceuil_page' },
        { page: 'first-jobs', title: 'first_job' },
        { page: 'internships', title: 'stage' },
        { page: 'student-jobs', title: 'student_job' },
        { page: 'student-rooms', title: 'kot' },
        { page: 'student-life', title: 'blog' }
    ]

    return (
        <Container maxWidth="md" className={classes.aboutLostContainer}>
            <Box className={classes.aboutLostContent}>
                <Typography variant="h1" className={classes.title}>
                    <FormattedMessage id="landing_aboutlost.banner" />
                </Typography>
                <Box className={classes.lostInfoContainer}>
                    <Typography variant='h3' component="h2" className={classes.subtitle}><FormattedMessage id="landing_aboutlost.lost_info.head_1" /></Typography>
                    <Typography variant='h3' component="h3" className={classes.subsubtitle}><FormattedMessage id="landing_aboutlost.lost_info.head_2" /></Typography>
                    {usefulPages.map((page) =>
                        <Typography key={page.title}>
                            <RouteMapLink className={classes.redirectLink} page={page.page}>
                                <FormattedMessage id={`landing_aboutlost.lost_info.redirect_link_${page.title}`} />
                            </RouteMapLink>
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box className={classes.aboutLostImg}>
                <Image
                    cloudName="studentbe"
                    publicId='assets/404_meme_3'
                    alt='404-lost-on-student-be'
                >
                    <Transformation q_auto='eco'/>
                </Image>
            </Box>
        </Container>
    )
}

export default LostPage
