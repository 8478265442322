import React from 'react'

const CursorSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
        <g>
            <g id="Layer_1" data-name="Layer 1">
                <path d="M374.72,287.65c1.67,1.19,2.88,1.81,3.8,2.73,22.38,22.33,44.73,44.69,67.08,67.06,5.36,5.36,5.39,9.02.11,14.31-13.37,13.39-26.75,26.77-40.14,40.14-5.15,5.14-8.85,5.09-14.04-.1-22.36-22.36-44.71-44.71-67.05-67.09-.93-.93-1.68-2.03-2.97-3.61-1.06,2.01-1.76,3.3-2.42,4.62-14.17,28.35-28.33,56.7-42.53,85.03-.8,1.59-1.64,3.27-2.88,4.49-4.1,4.04-10.25,2.3-12.64-3.58-5.12-12.63-10.14-25.3-15.2-37.96-37.77-94.47-75.55-188.95-113.32-283.42-.36-.89-.7-1.78-1.06-2.67-1.3-3.22-.8-6.15,1.67-8.59,2.48-2.44,5.42-2.8,8.62-1.52,10.32,4.14,20.65,8.24,30.97,12.37,96.78,38.73,193.57,77.46,290.35,116.19.55.22,1.1.48,1.66.67,3.72,1.22,6.39,3.44,6.53,7.61.13,4-2.39,6.2-5.75,7.87-22.9,11.4-45.77,22.86-68.65,34.31-7.03,3.52-14.04,7.05-22.14,11.12Z" fill='#000' strokeWidth={0} />
                <path d="M268.54,451.47c-1.39,0-2.79-.14-4.18-.43-7.26-1.5-13.37-6.77-16.34-14.11-3.71-9.15-7.37-18.32-11.03-27.5l-4.2-10.53L118.43,112.85c-3.44-8.55-1.64-17.47,4.84-23.85,6.35-6.25,15.21-7.96,23.71-4.55,5.72,2.29,11.44,4.57,17.17,6.86l13.8,5.51,290.35,116.2c.27.11.56.23.84.35l.2.08c9.7,3.27,15.67,10.89,15.98,20.44.17,5.21-1.37,14.85-13.53,20.9-18.71,9.31-37.39,18.67-56.08,28.02l-12.54,6.28c-1.34.67-2.67,1.34-4.02,2.01,18.11,18.09,36.21,36.2,54.31,54.3l2.1,2.1c10.88,10.89,10.93,23.34.12,34.17-13.37,13.4-26.76,26.78-40.16,40.16-10.59,10.57-23.27,10.53-33.9-.11-18.85-18.85-37.69-37.7-56.53-56.56l-4.56,9.12c-10.46,20.92-20.91,41.83-31.38,62.74-1.07,2.13-2.68,5.35-5.58,8.2-4.1,4.04-9.49,6.23-15,6.23ZM263.22,425.91h0,0ZM149.89,115.89l113.2,283.14c2.01,5.05,4.03,10.09,6.05,15.13,8.75-17.47,17.49-34.95,26.22-52.43l11.15-22.31c.48-.95.98-1.9,1.59-3.05l10.84-20.62,13.44,16.45c.53.64.98,1.22,1.38,1.74.27.35.5.66.74.93,21.3,21.33,42.69,42.73,64.09,64.13,11.46-11.44,22.9-22.89,34.34-34.34-21.3-21.31-42.61-42.63-63.94-63.91-.13-.09-.27-.17-.41-.27-.6-.39-1.27-.83-2-1.35l-19.07-13.52,28.3-14.22c5.15-2.59,9.95-5,14.76-7.41l12.54-6.28c14.98-7.5,29.97-15,44.96-22.48L153.73,117.42c-1.28-.51-2.56-1.02-3.83-1.53ZM368.6,300.32s0,0,0,0h0ZM459.71,229.39h0,0ZM144.49,102.35v.03s0-.02,0-.03Z" fill='#000' strokeWidth={0} />
                <path d="M354.65,267.58c1.67,1.19,2.88,1.81,3.8,2.73,22.38,22.33,44.73,44.69,67.08,67.06,5.36,5.36,5.39,9.02.11,14.31-13.37,13.39-26.75,26.77-40.14,40.14-5.15,5.14-8.85,5.09-14.04-.1-22.36-22.36-44.71-44.71-67.05-67.09-.93-.93-1.68-2.03-2.97-3.61-1.06,2.01-1.76,3.3-2.42,4.62-14.17,28.35-28.33,56.7-42.53,85.03-.8,1.59-1.64,3.27-2.88,4.49-4.1,4.04-10.25,2.3-12.64-3.58-5.12-12.63-10.14-25.3-15.2-37.96-37.77-94.47-75.55-188.95-113.32-283.42-.36-.89-.7-1.78-1.06-2.67-1.3-3.22-.8-6.15,1.67-8.59,2.48-2.44,5.42-2.8,8.62-1.52,10.32,4.14,20.65,8.24,30.97,12.37,96.78,38.73,193.57,77.46,290.35,116.19.55.22,1.1.48,1.66.67,3.72,1.22,6.39,3.44,6.53,7.61.13,4-2.39,6.2-5.75,7.87-22.9,11.4-45.77,22.86-68.65,34.31-7.03,3.52-14.04,7.05-22.14,11.12Z" fill='#fff' strokeWidth={0} />
                <path d="M248.47,431.4c-1.39,0-2.79-.14-4.18-.43-7.26-1.5-13.37-6.77-16.34-14.11-3.71-9.15-7.37-18.32-11.03-27.5l-4.2-10.53L98.35,92.78c-3.44-8.55-1.64-17.47,4.84-23.85,6.35-6.25,15.21-7.96,23.71-4.55,5.72,2.29,11.44,4.57,17.17,6.86l13.8,5.51,290.35,116.2c.27.11.56.23.84.35l.2.08c9.7,3.27,15.67,10.89,15.98,20.44.17,5.21-1.37,14.85-13.53,20.9-18.71,9.31-37.39,18.67-56.08,28.02l-12.54,6.28c-1.34.67-2.67,1.34-4.02,2.01,18.11,18.09,36.21,36.2,54.31,54.3l2.1,2.1c10.88,10.89,10.93,23.34.12,34.17-13.37,13.4-26.76,26.78-40.16,40.16-10.59,10.57-23.27,10.53-33.9-.11-18.85-18.85-37.69-37.7-56.53-56.56l-4.56,9.12c-10.46,20.92-20.91,41.83-31.38,62.74-1.07,2.13-2.68,5.35-5.58,8.2-4.1,4.04-9.49,6.23-15,6.23ZM243.15,405.84h0,0ZM129.82,95.82l113.2,283.14c2.01,5.05,4.03,10.09,6.05,15.13,8.75-17.47,17.49-34.95,26.22-52.43l11.15-22.31c.48-.95.98-1.9,1.59-3.05l10.84-20.62,13.44,16.45c.53.64.98,1.22,1.38,1.74.27.35.5.66.74.93,21.3,21.33,42.69,42.73,64.09,64.13,11.46-11.44,22.9-22.89,34.34-34.34-21.3-21.31-42.61-42.63-63.94-63.91-.13-.09-.27-.17-.41-.27-.6-.39-1.27-.83-2-1.35l-19.07-13.52,28.3-14.22c5.15-2.59,9.95-5,14.76-7.41l12.54-6.28c14.98-7.5,29.97-15,44.96-22.48L133.65,97.34c-1.28-.51-2.56-1.02-3.83-1.53ZM348.52,280.25s0,0,0,0h0ZM439.64,209.31h0,0ZM124.42,82.28v.03s0-.02,0-.03Z" fill='#000' stroke='#000' strokeMiterlimit={10} />
            </g>
        </g>
    </svg>
)

export default CursorSVG
