import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    popperDropDown: {
        zIndex: 50,
        width: '100%',
        position: 'absolute !important' // Overriding <tooltip> in HTML
    },
    paperDropDown: {
        maxWidth: '1285px',
        minWidth: '1200px',
        position: 'absolute',
        top: 80,
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: 'var(--border-radius)',
        boxShadow: 'var(--shadow-paper)',
        [theme.breakpoints.down('md')]: {
            top: 76
        }
    },
    dropDownContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '12px 12px'
    }

}))
