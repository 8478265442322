export const EBOOK_DOWNLOAD = 'ebook_download'
export const FAVOURITE = 'favourite'
export const KOT_SHOW = 'kot_show'
export const JOB_APPLICATION = 'job_application'
export const SAVE_FILTERS = 'save_filters'
export const JOB_SHOW = 'job_show'
export const JOB_FAIRS = 'jobfairs'
export const PROMOTION = 'promotion'
export const EMPLOYER_BUYING_CREDITS = 'employer_buying_credits'
export const MY_BS_REGISTRATION = 'myBS_registration'
export const JOIN_THE_CLUB = 'join_the_club'
