import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0 auto',
        width: '80%',
        [theme.breakpoints.down('lg')]: {
            width: '90%'
        },
    },
    title: {
        textAlign: 'center'
    },
    loadingMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    totals: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& span': {
            color: 'var(--background-purple)'
        }
    },
    totalsText: {
        margin: 0
    },
    sampleText: {
        textAlign: 'end',
        margin: 0,
    },
    exportButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 12
    },
    exportEmail: {
        '& span': {
            color: 'var(--background-purple)'
        }
    }
}))
