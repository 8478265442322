import React, { useState, useEffect } from 'react'

import { useStyles } from './LoginRegisterModal_style'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import LoginRegisterPage from '../../../pages/LoginRegister/LoginRegister/LoginRegisterPage'
import SessionAPI from 'api/SessionAPI'

const LoginRegisterModal = ({ showModal, setShowModal, origin, afterAuthAction, onClick, loginTitle, loginSubTitle }) => {
    const [authentificationData, setAuthentificationData] = useState({})
    const theme = useTheme()
    const classes = useStyles()

    useEffect(() => {
        showModal && getAuthentificationData()
    }, [showModal])

    const closeModal = () => {
        setShowModal(false)
    }

    const getAuthentificationData = () => {
        SessionAPI.GetAuthentificationData().then((response) => {
            setAuthentificationData(response.data)
        })
    }

    return (
        <Dialog
            maxWidth='md'
            open={showModal}
            onClose={closeModal}
            aria-labelledby="responsive-dialog-delete"
            classes={{ paper: classes.paperStyle }}
            onClick={onClick}
        >
            <DialogContent className={classes.dialogContainer}>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        right: 32,
                        top: 28,
                        color: theme.palette.grey[500]
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
                <LoginRegisterPage
                    {...authentificationData}
                    withoutContainer
                    origin={origin}
                    afterAuthAction={afterAuthAction}
                    loginTitle={loginTitle}
                    loginSubTitle={loginSubTitle}
                />
            </DialogContent>
        </Dialog>

    )
}

export default LoginRegisterModal
