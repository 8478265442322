import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        backgroundColor: theme.palette.bg['white']
    },

    appBar: {
        width: '100%',
    },

    toolbar: {
        width: '100%',
        padding: '0px',
        minHeight: 'unset'
    },

    homeHeader: {
        width: '100%',
        padding: '16px 16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    logo: {
        width: '143px',
        height: '44px'
    },

    closeButton: {
        padding: '0px',
        '&:hover': {
            background: 'transparent'
        },
    },

    closeIcon: {
        color: theme.palette.fg['black']
    },

    contentMenuContainer: {
        width: '100%'
    },

    footerMenuContainer: {
        width: '100%',
        padding: '0px 16px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '20px'
    },

    link: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '350px',
        },
    },

    redirectionButton: {
        width: '100% !important'
    },

    userActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        columnGap: '20px',
    },

    jobSelectionHeader: {
        width: '100%',
        padding: '32px 24px 20px',
        borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    jobSelectionHeaderLeftContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    leftArrowButton: {
        padding: '0px',
        paddingRight: '17px',
        '&:hover': {
            background: 'transparent'
        },
    },

    leftArrowIcon: {
        fontSize: '20px !important',
        color: theme.palette.fg['black']
    },

    jobSelectionHeaderTitle: {
        paddingLeft: '10px',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '18px'
    },

}))
