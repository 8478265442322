import React, { useState } from 'react'
import { useStyles } from '../../Searchbar_v2_style'
import { Box, Typography} from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const QuickFilterTag = (props) => {
    const classes = useStyles()
    const { page, quickFilterOption, quickFilterSelection, setFilters, filters } = props
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')
    const [isHoverText, setIsHoverText] = useState(null)

    const baseUrl = RouteMap.TrailingQuickFilterTag(page, quickFilterOption.value, quickFilterCollection[page])
    const params = window.location.search.replace(/quick_filter=[^&]*&?/, '')
            .replace(/\?$/, '')
            .replace(/[?&]$/, '')
    const redirectionUrl = `${baseUrl}${params}`

    const isSelected = quickFilterSelection === quickFilterOption.value

    const setQuickFilter = (selectedQuickFilter) => {
         window.history.pushState({}, '', baseUrl);

        if (selectedQuickFilter === filters.quickFilter) {
            setFilters({
                ...filters,
                quickFilter: ''
            })
        } else {
            setFilters({
                ...filters,
                quickFilter: selectedQuickFilter
            })
        }
    }

    return (
        <RouteMapLink
            key={quickFilterOption.value}
            className={classes.quickFilterOption}
            redirectionUrl={redirectionUrl}
            onClick={() => setQuickFilter(quickFilterOption.value)}
        >
            <Box className={`${classes.containerIcon} ${isSelected ? classes.selected : classes.notSelected} ${isHoverText === quickFilterOption.value ? classes.selected : classes.notSelected}  ${quickFilterOption.value === 'impact' && classes.containerIconImpact} `} >
                <SVGFactory
                    name={quickFilterOption.icon}
                    height="25" width="24"
                />
            </Box>
            <Typography 
                onMouseOver={() => setIsHoverText(quickFilterOption.value)} 
                onMouseLeave={() => setIsHoverText(null)}
                variant='h3'
                component='p'
                className={classes.quickFilterText}
            >
                <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
            </Typography>
        </RouteMapLink>
    )
}

export default QuickFilterTag
