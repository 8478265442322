import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutLostContainer: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    aboutLostCompanyContainer: {
        marginTop: 20
    },
    recommendationBox: {
        marginTop: 60
    },
    aboutLostContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            zIndex: '2',
            alignItems: 'center'
        }
    },
    title: {
        color: theme.palette.fg.black,
        marginTop: '45px',
        fontSize: 100,
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '24px',
            fontSize: 80
        }
    },
    subtitle: {
        fontSize: 40
    },
    subsubtitle: {
        fontSize: 24
    },
    redirectLink: {
        '&:hover': {
            color: 'var(--foreground-purple) !important'
        }
    },
    aboutLostImg: {
        display: 'flex',
        alignItems: 'flex-end',
        '& img': {
            height: 300,
            transform: 'scale(1.4)',
            [theme.breakpoints.down('sm')]: {
                height: 280,
                margin: '0 auto'
            }
        }
    },
    lostInfoContainer: {
        '& h1': {
            marginBottom: '15px',
            [theme.breakpoints.up('md')]: {
                fontSize: '30px'
            }
        },
        '& h2': {
            marginBottom: '15px'
        }
    }
}))
