import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from '../NavigationDropDowns_style'
import NavigationDropDownContainer from '../../shared/wrappers/NavigationDropDownContainer'
import NavigationDropDownFilters from '../../shared/NavigationDropDownFilters/NavigationDropDownFilters'
import NavigationDropDownTitle from '../../shared/NavigationDropDownTitle/NavigationDropDownTitle'
import NavigationLinkItems from '../../shared/NavigationLinkItems/NavigationLinkItems'

const NavigationDropDownInternships = (props) => {
    const classes = useStyles()
    const { type, itemType } = props

    return (
        <NavigationDropDownContainer {...props}>
            <Box className={classes.dropDownFieldsContainer}>
                <NavigationDropDownTitle type={type} />
                <NavigationDropDownFilters type={type} />
                <NavigationLinkItems type={type} itemType={itemType} />
            </Box>
        </NavigationDropDownContainer>
    )
}

export default NavigationDropDownInternships
