import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleHeaderContainer: {
        marginBottom: 30
    },
    mainGridContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    backButtonContainer: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            height: 44
        }
    },
    backButtonBox: {
        position: 'absolute',
        left: 0,
        top: 16
    },
    leaderBoardAd: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-90px'
        }
    },
    articleContentContainer: {
        margin: 'auto'
    },
    imageStyle: {
        width: '100%',
        borderRadius: '12px',
        marginBottom: 20
    },
    articleTextContainer: {
        overflowY: 'visible'
    },
    ebookDownloadContainer: {
        position: 'relative',
        borderRadius: '20px',
        backgroundColor: 'var(--background-yellow)',
        marginBottom: '20px',
        border: '1px solid var(--background-silver)',
        padding: '40px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 20px'
        }
    },
    ebookDownloadButton: {
        fontSize: 18,
        textTransform: 'uppercase'
    },
    ebookdownloadCursorIcon: {
        position: 'absolute',
        left: '38%',
        top: '55%',
        [theme.breakpoints.down('md')]: {
            top: '60%',
            left: '40%'
        },
        [theme.breakpoints.down('sm')]: {
            left: '52%'
        }
    },
    ebookdownloadStarIcon: {
        position: 'absolute',
        right: '30%',
        top: '15%',
        [theme.breakpoints.down('md')]: {
            right: '25%'
        },
        [theme.breakpoints.down('sm')]: {
            right: '15%'
        }
    },
    CTAAlert: {
        backgroundColor: 'var(--background-purple)',
        '& p': {
            color: 'white'
        }
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    recommendedJobsBox: {
        marginTop: 100,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        }
    }
}))
