/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'

import IconFactory from 'icons/IconFactory/IconFactory'
import { Tooltip, Box, IconButton, useTheme } from '@material-ui/core'

import LineButton from 'buttons/LineButton/LineButton'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import { useStyles } from './FavoriteAction_style'
import { FAVOURITE_API } from 'api/env'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { FAVOURITE } from 'assets/AuthentificationHelper'

const FavoriteAction = (props) => {
    const { fullButton, item, onItemCard, padding, size, userType, buttonClassName } = props
    const classes = useStyles()
    const theme = useTheme()
    const loginRegisterContext = useContext(LoginRegisterContext)

    const [isFavourite, setIsFavourite] = useState(item.is_favourite)
    const [favCount, setFavCount] = useState(item.favourite_count)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const openLoginModal = (event) => {
        event.preventDefault()
        loginRegisterContext.openLoginRegisterModal({
            origin: FAVOURITE,
            loginSubTitle: <FormattedMessage id='favorite_login.login_subtitle' />,
            onClick: e => e.stopPropagation() // Prevent to open jobshow when clicking on card favourite
        })
        event.stopPropagation()
    }

    const toggleFavourite = (event) => {
        event.preventDefault()
        const apiMethod = isFavourite ? 'delete' : 'post'
        axios({
            url: FAVOURITE_API,
            method: apiMethod,
            params: {
                id: item.id,
                type: item.type
            }
        }).then(response => {
            setIsFavourite(response.data.is_favourite)
            setFavCount(apiMethod === 'post' ? favCount + 1 : favCount - 1)
            if (apiMethod === 'post') {
                setFeedback({
                    open: true,
                    severity: 'success',
                    message: <FormattedMessage id="feedback_snackbar.added_to_favourites" />
                })
            }
        })

        event.stopPropagation()
    }

    const renderIcon = () => {
        let color = theme.palette.fg['black']
        let disableButton = false
        let className
        let onClick
        let icon = 'heart-full'
        const newPadding = onItemCard ? '4px 0 4px 10px' : padding || '10px'
        const fontSize = size || '1rem'

        if (userType && userType !== 'student' && userType !== 'not_connected') {
            // ICON FOR KOT OWNERS AND EMPLOYERS'
            color = 'var(--grey)'
            disableButton = true
            className = `${classes.disabled} disabled-fav-icon`
        } else if (isFavourite) {
            // ICON FOR STUDENTS, FAVOURITE IS TRUE
            color = 'var(--black)'
            className = `${classes.favorite} ${classes.pointer} is-fav-icon`
            onClick = toggleFavourite
        } else if (userType && userType !== 'not_connected') {
            // ICON FOR STUDENTS, FAVOURITE IS FALSE
            icon = 'heart-light'
            className = `${classes.favoriteAction} ${classes.pointer} is-not-fav-icon`
            onClick = toggleFavourite
        } else {
            // NON CONNECTED USERS
            icon = 'heart-light'
            className = `${classes.favoriteAction} ${classes.pointer} not-connected-fav-icon`
            onClick = openLoginModal
        }

        if (fullButton) {
            return (
                <>
                    <FeedbackSnackbar
                        open={feedback.open}
                        message={feedback.message}
                        severity={feedback.severity}
                        closeFeedback={closeFeedback}
                        autoHideDuration={1500}
                    />
                    <LineButton
                        fullWidth
                        newStyle
                        onClick={onClick}
                        disabled={disableButton}
                        className={buttonClassName}
                        name={
                            <Box display='flex' alignItems='center'>
                                <Box mr={1}>
                                    <IconFactory
                                        icon={icon}
                                        padding='0px 8px 0px 0px'
                                        fontSize={fontSize}
                                        color={color}
                                        className={className}
                                    />
                                </Box>
                                <FormattedMessage id='transl_internship.favorite_button'/>
                            </Box>
                        }
                    />
                </>
            )
        }

        return (
            <>
                <FeedbackSnackbar
                    open={feedback.open}
                    message={feedback.message}
                    severity={feedback.severity}
                    closeFeedback={closeFeedback}
                    autoHideDuration={1500}
                />
                <Tooltip title={<FormattedMessage id="transl_internship.favorite_button" />}>
                    <IconButton
                        onClick={onClick}
                        size='small'
                        style={{ borderRadius: '4px', minWidth: '30px', padding: newPadding, backgroundColor: 'white' }}
                        disabled={disableButton}
                    >
                        <Box display='flex' alignItems='center'>
                            <Box>
                                <IconFactory
                                    icon={icon}
                                    padding='0px'
                                    fontSize={fontSize}
                                    color={color}
                                    className={className}
                                />
                            </Box>
                            {(favCount > 0)
                                ? <Box ml={0.7} className={classes.heartCount} style={{ color: color }}>
                                    {favCount}
                                </Box> : (
                                    <Box ml={0.7} className={classes.noFav}></Box>
                                )
                            }
                        </Box>
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    return renderIcon()
}

export default FavoriteAction

/* ===== DOC =====

    USAGE :

    <IconButton>
        <FavoriteAction
            userType={user && user.type}
            item={job}
            size='large'
            setFavourite={this.setFavourite}
        />
    </IconButton>

    Mandatory:
        @userType: user type of current user
        @isFavourite: isFavorite state value passed from higher component.
            can be set by @setFavourite callBack function

        jobInfos:
            @type
            @id

    OPTIONAL:
        @size: ['small', 'large'], materialUI fontSize props
        @setFavourite: callBack function to set the favorite state
            value from higher hierarchy components

    ===== END =====
*/
