import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../NavigationDropDowns/NavigationDropDowns_style'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationLinkArticles = (props) => {
    const classes = useStyles()
    const { itemType } = props

    return (
        <Box className={classes.dropdownSeeMoreLink}>
            <RouteMapLink articleCategory={itemType} className={classes.seeAll}>
                <Typography variant='h3' component='div' color='secondary'>
                    <FormattedMessage id='page_footer_navbar_data.see_all_tips' />
                </Typography>
                <IconFactory icon='arrow-right' fontSize={13} color={'var(--background-purple)'} />
            </RouteMapLink>
        </Box>
    )
}

export default NavigationLinkArticles
