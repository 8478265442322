import React from 'react'
import { useStyles } from './LoginProviderModal_style'
import { Box, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import IconFactory from 'icons/IconFactory/IconFactory'
import ProviderLoginButton from 'components/ProviderLoginButton/ProviderLoginButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const LoginProviderModal = ({ showModal, setShowModal, loginProvider, renderLoginForm }) => {
    const classes = useStyles()

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <Dialog
            maxWidth='xs'
            open={showModal}
            onClose={closeModal}
            aria-labelledby='responsive-dialog-delete'
            PaperProps={{ className: classes.dialogContainer }}
        >
            <Box className={classes.dialogTitleBox}>
                <Typography className={classes.title} variant='h2'>
                    <FormattedMessage id='login_provider_modal.title' />
                </Typography>
                <IconButton
                    color='inherit'
                    onClick={closeModal}
                    aria-label='close'
                    className={classes.closeButton}
                >
                    <IconFactory icon='close'/>
                </IconButton>
            </Box>
            <DialogContent className={classes.dialogcontentBox}>
                <Typography className={classes.subTitle}>
                    <FormattedMessage id={`login_provider_modal.sub_title.${loginProvider}`} />
                </Typography>
                {loginProvider === 'facebook' && <ProviderLoginButton provider='facebook'/>}
                {loginProvider === 'google_oauth2' && <ProviderLoginButton provider='google'/>}
                {false && loginProvider === 'apple' && <ProviderLoginButton provider='apple'/>}
                <Box className={classes.passwordForgotBox}>
                    <Typography><FormattedMessage id='login_provider_modal.change_password_text' /></Typography>
                    <RouteMapLink page='password/new'>
                        <Typography component='div' className={classes.forgotPasswordLinkText}>
                            <FormattedMessage id='login_provider_modal.change_password' />
                        </Typography>
                    </RouteMapLink>
                </Box>
            </DialogContent>
        </Dialog>

    )
}

export default LoginProviderModal
