import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobShowContent_style'
import { Box, Typography, useTheme } from '@material-ui/core'

import Sanitizer from 'assets/Sanitizer'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const JobShowContent = (props) => {
    const { job } = props
    const classes = useStyles()
    const theme = useTheme()

    const checkIfStringIsPresent = (string) => {
        if (['<p></p>', '<p><br></p>', '', null, undefined].includes(string)) return false
        return true
    }

    return (
        <Box className={classes.jobContentContainer}>
            {checkIfStringIsPresent(job.description) &&
                <Box className={classes.section}>
                    <Box className={classes.sectionTitleContainer}>
                        <Box className={classes.iconContainer} sx={{ backgroundColor: theme.palette.bg.green }}>
                            <SVGFactory name='first-job' width='14px' height='14px' />
                        </Box>
                        <Typography
                            className={classes.sectionTitle}
                            variant='h1'
                            component='h2'
                        >
                            <FormattedMessage id='job_resume.job_description' />
                        </Typography>
                    </Box>
                    <Typography
                        className={classes.sectionBody}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.description) }}
                        component='div'
                    />
                </Box>
            }
            {checkIfStringIsPresent(job.searched_profile) &&
                <Box className={classes.section}>
                    <Box className={classes.sectionTitleContainer}>
                        <Box className={classes.iconContainer} sx={{ backgroundColor: theme.palette.bg.blue }}>
                            <SVGFactory name='graduate' width='14px' height='14px' />
                        </Box>
                        <Typography
                            className={classes.sectionTitle}
                            variant='h1'
                            component='h2'
                        >
                            <FormattedMessage id='job_resume.profile_required' />
                        </Typography>
                    </Box>
                    <Typography
                        className={classes.sectionBody}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.searched_profile) }}
                        component='div'
                    />
                </Box>
            }
            {checkIfStringIsPresent(job.we_offer) &&
                <Box className={classes.section}>
                    <Box className={classes.sectionTitleContainer}>
                        <Box className={classes.iconContainer} sx={{ backgroundColor: theme.palette.bg.orange }}>
                            <SVGFactory name='speak' width='14px' height='14px' />
                        </Box>
                        <Typography
                            className={classes.sectionTitle}
                            variant='h1'
                            component='h2'
                        >
                            <FormattedMessage id='job_resume.we_offer' />
                        </Typography>
                    </Box>
                    <Typography
                        className={classes.sectionBody}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.we_offer) }}
                        component='div'
                    />
                </Box>
            }
        </Box>
    )
}

export default JobShowContent
