import React from 'react'

const GoogleSVG = (props) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" {...props}>
      <g>
        <path d="M565.33,548.23v72.44c0,19.08,15.47,34.56,34.56,34.56h152.1c25.2,0,42.07,26.17,31.4,49-27.79,59.43-92.85,128.39-218.05,128.39-140.39,0-255.06-116.33-255.06-259.87,0-220.21,246.91-327.03,397.05-214.84,13.48,10.07,32.31,8.75,44.44-2.91l61.98-59.6c15.24-14.65,13.88-39.44-2.88-52.32-67.06-51.52-149.72-81.88-245.52-81.88-227.68,0-411.55,183.87-411.55,411.55s183.87,411.55,411.55,411.55,398.27-167.11,398.27-401.92c0-14.19-.71-26.46-1.93-37.92-1.86-17.53-16.73-30.78-34.36-30.78h-327.43c-19.08,0-34.56,15.47-34.56,34.56Z"/>
        <path d="M565.33,1012c-117.51,0-227.87-45.64-310.74-128.51-82.87-82.87-128.51-193.23-128.51-310.74s45.64-227.87,128.51-310.74c82.87-82.87,193.23-128.51,310.74-128.51,49.72,0,97.66,7.78,142.5,23.11,42.98,14.7,83.31,36.4,119.9,64.5,14.39,11.06,23.22,27.79,24.23,45.92,1,18.13-5.93,35.75-19.02,48.34l-61.98,59.6c-22.07,21.22-55.8,23.38-80.22,5.13-31.56-23.58-69.73-37.16-110.39-39.26-39.33-2.03-79.35,6.53-115.74,24.76-37.1,18.59-68.31,46.3-90.26,80.13-24.13,37.19-36.37,79.92-36.37,127.02,0,128.02,101.99,232.17,227.36,232.17,50.92,0,94.56-12.43,129.72-36.94,34.26-23.89,53.3-54.24,63.24-75.49,1.26-2.69.45-4.93-.44-6.34-.77-1.21-2.55-3.23-5.86-3.23h-152.1c-34.33,0-62.26-27.93-62.26-62.26v-72.44c0-34.33,27.93-62.26,62.26-62.26h327.43c31.92,0,58.54,23.88,61.9,55.56,1.4,13.22,2.09,26.58,2.09,40.85,0,61.24-10.33,119.05-30.69,171.83-20.11,52.13-49.5,98.09-87.34,136.6-38.04,38.72-83.55,68.81-135.27,89.44-52.81,21.07-110.91,31.75-172.68,31.75ZM565.33,188.91c-102.72,0-199.16,39.88-271.56,112.28-72.41,72.41-112.28,168.85-112.28,271.57s39.87,199.16,112.28,271.57c72.41,72.41,168.85,112.28,271.57,112.28,54.7,0,105.89-9.36,152.15-27.81,44.54-17.77,83.66-43.61,116.28-76.81,32.5-33.08,57.79-72.68,75.16-117.71,17.9-46.4,26.98-97.5,26.98-151.89,0-12.29-.58-23.74-1.78-35-.36-3.42-3.29-6.01-6.81-6.01h-327.43c-3.78,0-6.85,3.07-6.85,6.85v72.44c0,3.78,3.07,6.85,6.85,6.85h152.1c21.43,0,41.09,10.8,52.6,28.89,11.46,18,12.91,40.26,3.89,59.55-20.3,43.4-84.62,144.36-243.15,144.36s-282.76-129-282.76-287.57c0-111.4,58.77-207.36,157.21-256.68,44.94-22.52,94.53-33.09,143.43-30.56,51.66,2.67,100.31,20.04,140.69,50.21,2.55,1.9,6.27,1.61,8.65-.69l61.99-59.6c1.88-1.8,2.18-3.9,2.1-5.33-.08-1.43-.6-3.47-2.66-5.05-65.77-50.53-142.7-76.15-228.65-76.15Z"/>
      </g>
      <g>
        <path d="M523.51,485.2v58.3c0,20.15,16.34,36.49,36.49,36.49h129.47c27.5,0,45,29.2,32.25,53.57-27.77,53.08-87.75,110.95-198.2,110.95-130.2,0-236.54-107.88-236.54-241,0-202.8,225.82-301.91,365.31-201.38,14.35,10.34,33.97,9.19,46.72-3.07l51.13-49.16c16.25-15.62,14.6-42.11-3.48-55.57-61.37-45.68-136.45-72.49-223.14-72.49-211.15,0-381.66,170.52-381.66,381.66s170.52,381.66,381.66,381.66,369.35-154.97,369.35-372.74c0-11.21-.48-21.14-1.32-30.4-1.71-18.84-17.43-33.31-36.35-33.31h-295.2c-20.15,0-36.49,16.34-36.49,36.49Z"/>
        <path d="M523.51,939.03c-116.51,0-225.93-45.26-308.1-127.43-82.17-82.17-127.43-191.59-127.43-308.1s45.26-225.93,127.43-308.1c82.17-82.17,191.59-127.43,308.1-127.43,93.95,0,182.23,28.75,255.3,83.15,21.34,15.88,34.54,40.22,36.21,66.78,1.67,26.56-8.37,52.38-27.56,70.82l-51.13,49.16c-31.42,30.21-80.01,33.55-115.54,7.94-50.25-36.21-119.05-39.47-179.56-8.49-29.53,15.12-54.32,37.4-71.67,64.42-19.06,29.69-28.72,63.92-28.72,101.75,0,103.19,81.95,187.14,182.68,187.14,39.59,0,73.44-9.01,100.63-26.79,13.97-9.13,24.84-19.72,33.2-30h-97.34c-49.82,0-90.35-40.53-90.35-90.35v-58.3c0-49.82,40.53-90.35,90.35-90.35h295.2c47.05,0,85.73,35.38,89.99,82.3,1.04,11.42,1.54,22.96,1.54,35.27,0,60.27-10.21,117.3-30.35,169.5-20.05,51.95-49.38,97.81-87.18,136.28-38.03,38.71-83.49,68.78-135.11,89.37-52.31,20.87-109.71,31.45-170.58,31.45ZM523.51,175.7c-87.73,0-170.1,34.05-231.92,95.88s-95.88,144.19-95.88,231.92,34.05,170.1,95.88,231.92c61.83,61.83,144.19,95.88,231.92,95.88,92.76,0,171.9-30.64,228.84-88.59,27.43-27.92,48.8-61.41,63.52-99.56,15.35-39.78,23.13-83.76,23.13-130.72,0-3.37-.05-6.64-.15-9.85h-261.47v23.55h112.09c31.87,0,60.74,16.27,77.23,43.51,16.54,27.32,17.57,60.55,2.74,88.89-20.31,38.82-50.17,71.84-86.34,95.49-45.01,29.43-98.7,44.35-159.58,44.35-160.13,0-290.4-132.28-290.4-294.87,0-57.97,15.84-113.28,45.8-159.95,27.62-43.02,66.77-78.33,113.23-102.11,45.54-23.32,96.03-34.6,146-32.62,48.27,1.91,94.22,16.22,133.79,41.55l25.62-24.63c-51.2-33.22-109.64-50.04-174.03-50.04Z"/>
      </g>
      <path d="M523.51,485.2v58.3c0,20.15,16.34,36.49,36.49,36.49h129.47c27.5,0,45,29.2,32.25,53.57-27.77,53.08-87.75,110.95-198.2,110.95-130.2,0-236.54-107.88-236.54-241,0-202.8,225.82-301.91,365.31-201.38,14.35,10.34,33.97,9.19,46.72-3.07l51.13-49.16c16.25-15.62,14.6-42.11-3.48-55.57-61.37-45.68-136.45-72.49-223.14-72.49-211.15,0-381.66,170.52-381.66,381.66s170.52,381.66,381.66,381.66,369.35-154.97,369.35-372.74c0-11.21-.48-21.14-1.32-30.4-1.71-18.84-17.43-33.31-36.35-33.31h-295.2c-20.15,0-36.49,16.34-36.49,36.49Z" fill="#fff"/>
    </svg>
)

export default GoogleSVG
