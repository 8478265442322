import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewOfferButton_style'

import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'
import { useWindowSize } from 'hooks/useWindowSize'
import Button from 'shared/components/Button/Button.jsx'

const NewOfferButton = (props) => {
    const { userType, type } = props
    const classes = useStyles()
    const { isLargeScreen } = useWindowSize()

    const renderOfferButton = (redirectionUrl, id) => {
        return (
            <RouteMapLink
                redirectionUrl={redirectionUrl}
                className={classes.button}
            >
                <FormattedMessage id={id}>
                    {(nameBtn) => (
                        <Button variant='cta-primary' className={classes.customButton}>
                            {nameBtn}
                        </Button>
                    )}
                </FormattedMessage>
            </RouteMapLink>
        )
    }
    const renderNewOfferButton = () => {
        const newEmployerUrl = userType
            ? RouteMap.Page('employer/new-ad')
            : `${RouteMap.Page('login')}?redirect=${RouteMap.Page(
                'employer/new-ad'
            )}&userType=employer`
        const newKotUrl = userType
            ? RouteMap.Page('users/kots/new')
            : `${RouteMap.Page('login')}?redirect=${RouteMap.Page(
                'users/kots/new'
            )}&userType=kot_owner`
        let offerButton = null
        if (userType) {
            if (userType === 'employer' && JOB_TYPES_LIST.includes(type)) {
                offerButton = renderOfferButton(
                    newEmployerUrl,
                    `item_offers.${type}.new_ad`
                )
            } else if (userType === 'kot_owner' && type === 'kot') {
                offerButton = renderOfferButton(
                    newKotUrl,
                    'item_offers.kot.new_ad'
                )
            }
        } else {
            if (JOB_TYPES_LIST.includes(type)) {
                offerButton = renderOfferButton(
                    newEmployerUrl,
                    `item_offers.${type}.new_ad_disconnected_v2`
                )
            } else if (type === 'kot' && isLargeScreen) {
                offerButton = renderOfferButton(
                    newKotUrl,
                    'item_offers.kot.new_ad'
                )
            }
        }
        return offerButton
    }

    return renderNewOfferButton()
}

export default NewOfferButton
