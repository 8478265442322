import React from 'react'

const StarSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 82.94">
        <g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1">
            <path d="M85.53,34.61a8,8,0,0,0-6.13-5.19l-7.66-1.35L61.6,26.29l-8-15-.34-.65-.24-.44a8,8,0,0,0-7-4.21,8.13,8.13,0,0,0-7.16,4.25l-.16.29L34.86,18,33,21.45q-1.26,2.43-2.53,4.84l-8.09,1.42-7.8,1.36-1.43.26-.6.1a8,8,0,0,0-4.37,13.4l.32.33L20.86,55.91,18.33,73.85a8.26,8.26,0,0,0-.08,1.1h0a8,8,0,0,0,3.83,6.78,8.11,8.11,0,0,0,7.83.34l5.63-2.79L46,74.12l15.06,7.46,1.13.55a8,8,0,0,0,3.49.81,8.06,8.06,0,0,0,8-9l-.06-.52q-.59-4-1.15-8.07c-.32-2.23-.64-4.46-1-6.73l-.22-1.54c0-.38-.11-.76-.16-1.14L83.56,43.1l.12-.14c.16-.17.31-.34.48-.55A8,8,0,0,0,85.53,34.61Z"/><path d="M79.53,28.61a8,8,0,0,0-6.13-5.19l-7.66-1.35L55.6,20.29l-8-15-.35-.65-.23-.44a8,8,0,0,0-7-4.21,8.13,8.13,0,0,0-7.16,4.25l-.16.29L28.86,12,27,15.45q-1.26,2.43-2.53,4.84l-8.09,1.42-7.8,1.36-1.43.26-.6.1a8,8,0,0,0-4.37,13.4l.32.33q6.16,6.39,12.33,12.75L12.33,67.85a8.26,8.26,0,0,0-.08,1.1h0a8,8,0,0,0,3.83,6.78,8.11,8.11,0,0,0,7.83.34l5.63-2.79L40,68.12l15.06,7.46,1.13.55a8,8,0,0,0,3.49.81,8,8,0,0,0,8-9c0-.17,0-.35,0-.52q-.59-4-1.15-8.07c-.32-2.23-.64-4.46-1-6.73l-.22-1.54c0-.38-.11-.76-.16-1.14L77.56,37.1l.12-.14c.16-.17.31-.34.48-.55A8,8,0,0,0,79.53,28.61Z"/><path fill='#fff' d="M20.25,69l.42-.2,19-9.42a.61.61,0,0,1,.61,0l18.26,9c.38.18.76.36,1.17.57,0-.17,0-.3,0-.42-.39-2.7-.77-5.39-1.15-8.09q-.48-3.36-.95-6.73c-.3-2.1-.59-4.21-.91-6.32a.52.52,0,0,1,.21-.48L71.81,31.53c.06-.06.11-.14.19-.23L64.36,30l-13.6-2.38a.75.75,0,0,1-.59-.43q-5-9.42-10-18.83c0-.11-.12-.22-.19-.35l-.17.31L36,15.67c-2,3.85-4,7.69-6,11.54a.56.56,0,0,1-.47.34c-.8.12-1.59.27-2.38.41l-9.28,1.63L10,31l-2,.35.28.29L23.11,46.93a.54.54,0,0,1,.19.52l-.93,6.45q-.95,6.72-1.9,13.44c-.07.52-.14,1-.22,1.61Z"/>
        </g></g>
    </svg>

)

export default StarSVG
