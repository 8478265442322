import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        padding: '10px 20px',
        textTransform: 'none'
    },
    iconBox: {
        width: '33px',
        height: '33px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg.silver,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
