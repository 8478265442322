/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'

const PaidLine = (props) => {
    const { job } = props

    const renderLocationLine = () => {
        if (job.address.street && job.address.city) {
            return `${job.address.street}, ${job.address.city}`
        } else {
            return job.address.city
        }
    }

    return (
        <InfoLineView
            icon='location-pin'
            text={renderLocationLine()}
        />
    )
}

export default PaidLine
